import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  Image,
  Modal,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flex from "components/common/Flex";
import CardDropdown from "components/common/CardDropdown";
import CSRFToken from "../../../../helpers/CSRFToken";
import TinymceEditor from "../../../../components/common/TinymceEditor";
import { Field, FieldArray } from "formik";

const CourseResources = ({ values, setFieldValue, errors, index }) => {
  const [resourceModal, setResourceModal] = useState(false);

  const handleSubmit = (e) => {
    setResourceModal(!resourceModal);
  };

  const handleImageChange = (file, field) => {
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFieldValue(field.name, reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <FieldArray
      name={"lessons"}
      render={(arrayHelpers) => (
        <Card className="mb-3">
          <Card.Header as="h5">Create Resources</Card.Header>
          <Card.Body className="p-0">
            <div className="table-responsive scrollbar">
              <Table borderless className="fs-10 text-end mb-0">
                <>
                  <tbody>
                    {values?.lessons?.map((resource, index) => (
                      <tr
                        key={index}
                        className={`btn-reveal-trigger ${
                          index % 2 === 0 && "bg-body-tertiary"
                        }`}
                      >
                        <td className="align-middle white-space-nowrap text-start">
                          <Flex
                            alignItems="center"
                            className="position-relative gap-3"
                          >
                            <Image
                              src={resource?.image}
                              width={60}
                              className="rounded-1 border border-200"
                            />
                            <div>
                              <h5 className="fs-9 text-primary">
                                {resource?.title}
                                <OverlayTrigger
                                  overlay={
                                    <Tooltip
                                      style={{ position: "fixed" }}
                                      id="courseResourceTooltip"
                                    >
                                      {resource?.is_fulfilled
                                        ? "Requirement fulfilled"
                                        : "No. of Slide/Test doesn't fulfill requirement"}
                                    </Tooltip>
                                  }
                                >
                                  <span className="ms-2 text-primary fs-9">
                                    <FontAwesomeIcon
                                      icon={
                                        resource?.is_fulfilled
                                          ? "check-circle"
                                          : "exclamation-circle"
                                      }
                                    />
                                  </span>
                                </OverlayTrigger>
                              </h5>
                              <p
                                className="fs-10 text-900 mb-0"
                                dangerouslySetInnerHTML={{
                                  __html: resource?.excerpt,
                                }}
                              ></p>
                            </div>
                          </Flex>
                        </td>
                        <td className="align-middle white-space-nowrap text-end">
                          <CardDropdown
                            children={
                              <Dropdown.Item
                                className="text-danger"
                                onClick={() => arrayHelpers.remove(index)}
                              >
                                Remove
                              </Dropdown.Item>
                            }
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <Modal
                    show={resourceModal}
                    onHide={() => setResourceModal(!resourceModal)}
                    size={"lg"}
                    centered
                  >
                    <Modal.Header closeButton>
                      <h5>Add New Chapter</h5>
                    </Modal.Header>
                    <Modal.Body>
                      <Form>
                        <CSRFToken />
                        <Row>
                          <Col>
                            <Form.Group>
                              <Form.Label>
                                Title <span className={"text-danger"}>*</span>
                              </Form.Label>
                              <Field name={`lessons.${index + 1}.title`}>
                                {({ field }) => (
                                  <Form.Control
                                    type={"text"}
                                    value={field.value}
                                    onChange={(e) =>
                                      setFieldValue(field.name, e.target.value)
                                    }
                                    placeholder={"Title"}
                                  />
                                )}
                              </Field>
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group>
                              <Form.Label>
                                Image <span className={"text-danger"}>*</span>
                              </Form.Label>
                              <Field name={`lessons.${index + 1}.image`}>
                                {({ field }) => (
                                  <Form.Control
                                    type={"file"}
                                    onChange={(e) => {
                                      handleImageChange(
                                        e.target.files[0],
                                        field
                                      );
                                    }}
                                  />
                                )}
                              </Field>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Form.Group>
                              <Form.Label>
                                Description
                                <span className="text-danger">*</span>
                              </Form.Label>
                              <Field name={`lessons.${index + 1}.excerpt`}>
                                {({ field }) => (
                                  <div className="create-course-description-textarea">
                                    <TinymceEditor
                                      height="13.438rem"
                                      handleChange={(newValue) => {
                                        setFieldValue(field.name, newValue);
                                      }}
                                    />
                                  </div>
                                )}
                              </Field>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Form.Group>
                              <Field name={`lessons.${index + 1}.is_locked`}>
                                {({ field }) => (
                                  <Form.Switch
                                    className={"mt-3"}
                                    label={"Locked"}
                                    checked={field.value}
                                    onChange={(e) =>
                                      setFieldValue(
                                        field.value,
                                        e.target.checked
                                      )
                                    }
                                  />
                                )}
                              </Field>
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group>
                              <Field name={`lessons.${index + 1}.is_fulfilled`}>
                                {({ field }) => (
                                  <Form.Switch
                                    className={"mt-3"}
                                    label={"Fulfilled"}
                                    checked={field.value}
                                    onChange={(e) =>
                                      setFieldValue(
                                        field.name,
                                        e.target.checked
                                      )
                                    }
                                  />
                                )}
                              </Field>
                            </Form.Group>
                          </Col>
                        </Row>
                      </Form>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        onClick={() => setResourceModal(!resourceModal)}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="primary"
                        onClick={(e) => {
                          handleSubmit(e);
                        }}
                      >
                        Save{" "}
                        <FontAwesomeIcon icon="paper-plane" className="ms-2" />
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </>
              </Table>
            </div>
          </Card.Body>
          <Card.Footer className="text-center py-2">
            <Button
              variant="link"
              size="sm"
              onClick={() => {
                setResourceModal(!resourceModal);
                arrayHelpers.insert(index + 1, "");
              }}
            >
              <FontAwesomeIcon icon="plus" className="me-1 fs-11" />
              Add New Chapter
            </Button>
          </Card.Footer>
        </Card>
      )}
    />
  );
};

export default CourseResources;
