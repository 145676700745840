import FalconCardHeader from "components/common/FalconCardHeader";
import TooltipBadge from "components/common/TooltipBadge";
import React, { useState } from "react";
import { Button, Card, Form, Spinner } from "react-bootstrap";
import { api } from "../../../utils/api";
import { toast } from "react-toastify";
import Flex from "../../../components/common/Flex";
import FormError from "../../errors/FormError";

const AccountSettings = ({ settings }) => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    visibility: settings?.visibility,
    tag: settings?.tag,
    show_followers: settings?.show_followers,
    show_emails: settings?.show_emails,
    show_experience: settings?.show_experience,
    phone_visible: settings?.phone_visible,
    user_follow: settings?.user_follow,
    newsletter_subscribed: settings?.newsletter_subscribed,
  });

  const handleChange = (e) => {
    if (e.target.type === "checkbox") {
      setFormData({
        ...formData,
        [e.target.name]: e.target.checked,
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    await api
      .patch(`/account/settings/${settings?.id}/`, formData)
      .then((res) => {
        toast.success("Settings updated successfully.", { theme: "colored" });
      })
      .catch((err) => {
        toast.error("An error has occurred.", { theme: "colored" });
        setErrors(err?.response?.data);
      });
    setLoading(false);
  };

  return loading ? (
    <Flex justifyContent="center" className="p-2 mb-2">
      <Spinner animation={"border"} variant={"primary"} />
    </Flex>
  ) : (
    <Card className="mb-3">
      <FalconCardHeader title="Account Settings" />
      <Card.Body className="bg-light">
        <div>
          <h6 className="fw-bold">
            Who can see your profile ?
            <TooltipBadge
              tooltip="Only The group of selected people can see your profile"
              icon="question-circle"
            />
          </h6>
          <div className="ps-2 mb-2">
            <Form.Check
              type="radio"
              id="profile-everyone"
              label="Everyone"
              className="form-label-nogutter"
              value="everyone"
              name="visibility"
              onChange={handleChange}
              checked={formData.visibility === "everyone"}
            />
            <Form.Check
              type="radio"
              id="profile-followers"
              label="My Members"
              className="form-label-nogutter"
              value="followers"
              name="visibility"
              onChange={handleChange}
              checked={formData.visibility === "followers"}
            />
            <Form.Check
              type="radio"
              id="profile-members"
              label="Only Me"
              className="form-label-nogutter"
              value="me"
              name="visibility"
              onChange={handleChange}
              checked={formData.visibility === "me"}
            />
          </div>
          <FormError error={errors?.visibility} />
        </div>

        <div>
          <h6 className="fw-bold">
            Who can tag you ?
            <TooltipBadge
              tooltip="Only The group of selected people can tag your"
              icon="question-circle"
            />
          </h6>
          <div className="ps-2">
            <Form.Check
              type="radio"
              id="tag-everyone"
              label="Everyone"
              className="form-label-nogutter"
              value="everyone"
              name="tag"
              onChange={handleChange}
              checked={formData.tag === "everyone"}
            />
            <Form.Check
              type="radio"
              id="tag-members"
              label="Group Members"
              className="form-label-nogutter"
              value="members"
              name="tag"
              onChange={handleChange}
              checked={formData.tag === "members"}
            />
          </div>
          <FormError error={errors?.tag} />
        </div>

        <div className="border-dashed border-bottom my-3" />

        <div className="ps-2">
          <Form.Check
            type="checkbox"
            id="show-followers"
            label="Allow users to show your followers"
            className="form-label-nogutter"
            name="show_followers"
            onChange={handleChange}
            checked={formData.show_followers}
          />
          <FormError error={errors?.show_followers} />
          <Form.Check
            type="checkbox"
            id="show-email"
            label="Allow users to show your emails"
            className="form-label-nogutter"
            name="show_emails"
            onChange={handleChange}
            checked={formData.show_emails}
          />
          <FormError error={errors?.show_emails} />
          <Form.Check
            type="checkbox"
            id="show-experience"
            label="Allow users to show your experiences"
            className="form-label-nogutter"
            name="show_experience"
            onChange={handleChange}
            checked={formData.show_experience}
          />
          <FormError error={errors?.show_experience} />
        </div>

        <div className="border-dashed border-bottom my-3" />

        <div className="ps-2">
          <Form.Check
            type="switch"
            id="custom-switch"
            label="Make your phone number visible"
            className="form-label-nogutter"
            name="phone_visible"
            onChange={handleChange}
            checked={formData.phone_visible}
          />
          <FormError error={errors?.phone_visible} />
          <Form.Check
            type="switch"
            id="custom-switch"
            label="Allow user to follow you"
            className="form-label-nogutter"
            name="user_follow"
            onChange={handleChange}
            checked={formData.user_follow}
          />
          <FormError error={errors?.user_follow} />
        </div>
        <div className="text-end mt-3">
          <Button variant="primary" type="submit" onClick={handleSubmit}>
            Update
          </Button>
        </div>
      </Card.Body>
    </Card>
  );
};

export default AccountSettings;
