import { Field, FieldArray } from "formik";
import { Card, Col, Form, Row } from "react-bootstrap";
import CustomDateInput from "../../../../components/common/CustomDateInput";
import IconButton from "../../../../components/common/IconButton";
import React, { useState } from "react";
import DatePicker from "react-datepicker";

const EducationForm = ({ values, setFieldValue, errors }) => {
  const [date, setDate] = useState(null);
  return (
    <FieldArray
      name={"educations"}
      render={(arrayHelpers) => (
        <div>
          <h5>Education</h5>
          {values?.educations && values.educations.length > 0 ? (
            values.educations.map((education, index) => (
              <Card
                key={index}
                variant="outlined"
                style={{ marginBottom: "1rem" }}
              >
                <Card.Header>
                  <h6>Education {index + 1}</h6>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col lg={6}>
                      <Form.Group>
                        <Form.Label>
                          School <span className={"text-danger"}>*</span>
                        </Form.Label>
                        <Field name={`certifications.${index}.school`}>
                          {({ field }) => (
                            <Form.Control
                              type="text"
                              value={field.value}
                              size={"sm"}
                              onChange={(e) =>
                                setFieldValue(field.name, e.target.value)
                              }
                            />
                          )}
                        </Field>
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group>
                        <Form.Label>
                          Degree <span className={"text-danger"}>*</span>
                        </Form.Label>
                        <Field name={`certifications.${index}.degree`}>
                          {({ field }) => (
                            <Form.Control
                              type="text"
                              value={field.value}
                              size={"sm"}
                              onChange={(e) =>
                                setFieldValue(field.name, e.target.value)
                              }
                            />
                          )}
                        </Field>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>
                          Date <span className={"text-danger"}>*</span>
                        </Form.Label>
                        <Field name={`certifications.${index}.date`}>
                          {({ field }) => (
                            <DatePicker
                              selected={date}
                              onChange={(date) => {
                                setDate(date);
                                setFieldValue(field.name, date);
                              }}
                              customInput={<CustomDateInput />}
                            />
                          )}
                        </Field>
                      </Form.Group>
                    </Col>
                  </Row>

                  <IconButton
                    variant="danger"
                    size="sm"
                    onClick={() => arrayHelpers.remove(index)}
                    aria-label="Delete education"
                    style={{ marginTop: "1rem" }}
                    icon={"trash-alt"}
                  ></IconButton>
                </Card.Body>
              </Card>
            ))
          ) : (
            <p>No education added yet.</p>
          )}
          <IconButton
            icon={"plus"}
            variant="contained"
            color="primary"
            size="sm"
            onClick={() =>
              arrayHelpers.push({ school: "", degree: "", date: "" })
            }
          >
            Add Education
          </IconButton>
        </div>
      )}
    />
  );
};

export default EducationForm;
