import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Nav } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { HeaderLanguages } from "../../components/navbar/top/HeaderLanguages";
import paths from '../../routes/paths';

const LandingRightSideNavItem = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('topbar');

  return (
    <Nav navbar className="ms-auto">
      <Nav.Item className={'mt-1'}>
        <HeaderLanguages />
      </Nav.Item>
      <Nav.Item>
        <Nav.Link className={'text-primary me-2'} as={Link} to="#offers">
          {t('offers')}
        </Nav.Link>
      </Nav.Item>

      <Nav.Item>
        <Nav.Link className={'text-primary me-2'} as={Link} to="#testimonials">
          {t('testimonials')}
        </Nav.Link>
      </Nav.Item>

      <Nav.Item>
        <Nav.Link className={'text-primary me-2'} as={Link} to="#contact">
          {t('contact')}
        </Nav.Link>
      </Nav.Item>

      <Nav.Item>
        <Nav.Link
          as={Button}
          className={'bg-transparent btn-primary text-primary ms-2'}
          onClick={() => navigate(paths.cardLogin)}
        >
          {t('login')}
        </Nav.Link>
      </Nav.Item>
    </Nav>
  );
};

export default LandingRightSideNavItem;
