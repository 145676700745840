import { useAppContext } from "../../../providers/AppProvider";
import React, { useEffect, useState } from "react";
import { Card, CardHeader, Form, Spinner } from "react-bootstrap";
import Flex from "../../../components/common/Flex";
import { Formik } from "formik";
import CSRFToken from "../../../helpers/CSRFToken";
import TeacherGigForm from "./TeacherGigForm";
import StudentGigForm from "./StudentGigForm";
import IconButton from "../../../components/common/IconButton";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { api } from "../../../utils/api";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import paths from "../../../routes/paths";

const GigAddEdit = () => {
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    posted_by: "",
    created_by: "",
    is_active: false,
    resources: [],
    schedules: [],
  });
  const [loading, setLoading] = useState(false);
  const {
    config: { group },
  } = useAppContext();
  const { id } = useParams();
  const navigate = useNavigate();

  const fetchGig = async () => {
    setLoading(true);
    await api
      .get(`/gig/teacher/${id}/`)
      .then(({ data }) => {
        const updatedSchedules = data?.schedules_data?.map((schedule) => ({
          end_time: schedule?.end_time,
          start_time: schedule?.start_time,
          max_participants: schedule?.max_participants,
        }));
        const updatedResources = data?.resources_data?.map((resource) => ({
          data: resource?.data,
        }));
        setFormData({
          ...data,
          schedules: updatedSchedules,
          resources: updatedResources,
        });
      })
      .catch((err) => {
        if (err?.response?.status === 404) navigate(paths.error404);
      });
    setLoading(false);
  };

  useEffect(() => {
    if (id) fetchGig();
    // eslint-disable-next-line
  }, []);

  const handleSubmit = async (e, values) => {
    setLoading(true);
    await api
      .post(`/gig/${group}/`, values)
      .then((res) => {
        toast.success("Gig successfully created.", { theme: "colored" });
        navigate(paths.gigList);
      })
      .catch((err) => {
        setFormData(values);
        toast.error("An error has occurred.", { theme: "colored" });
        setErrors(err?.response?.data);
      });
    setLoading(false);
  };

  return loading ? (
    <Flex justifyContent="center" className="p-2 mb-2">
      <Spinner animation={"border"} variant={"primary"} />
    </Flex>
  ) : (
    <Formik initialValues={formData} onSubmit={(values) => console.log(values)}>
      {({ values, setFieldValue }) => (
        <Form>
          <CSRFToken />
          {group === process.env.REACT_APP_TEACHER_GROUP_NAME ? (
            <TeacherGigForm
              values={values}
              setFieldValue={setFieldValue}
              errors={errors}
            />
          ) : group === process.env.REACT_APP_STUDENT_GROUP_NAME ? (
            <StudentGigForm
              values={values}
              setFieldValue={setFieldValue}
              errors={errors}
            />
          ) : (
            ""
          )}
          <Card className={"mt-3"}>
            <CardHeader>
              <Flex
                justifyContent={"between"}
                alignItems={"center"}
                wrap={"wrap"}
              >
                <span>
                  <ul>
                    <li className={"text-decoration-none text-danger"}>
                      * Mandatory Field
                    </li>
                    <li className={"text-decoration-none text-warning"}>
                      * Recommended Field
                    </li>
                  </ul>
                </span>
                <IconButton
                  icon={faSave}
                  onClick={(e) => handleSubmit(e, values)}
                >
                  <span className="d-none d-sm-inline-block ms-1">Save</span>
                </IconButton>
              </Flex>
            </CardHeader>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export default GigAddEdit;
