import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import Background from "components/common/Background";
import corner6 from "assets/img/illustrations/corner-6.png";
import { Button, Card, Col, Form, Row } from "react-bootstrap";

const GigHeader = ({ layout, setShowFilterOffcanvas }) => {
  return (
    <Card className="mb-3">
      <Card.Header className="position-relative">
        <h5 className="mb-0 mt-1">All Gigs</h5>
        <Background image={corner6} className="d-none d-md-block bg-card" />
      </Card.Header>
      <Card.Body className="pt-0 pt-md-3">
        <Row className="g-3 align-items-center">
          <Col xs="auto" className="d-xl-none">
            <Button
              onClick={() => setShowFilterOffcanvas(true)}
              className="position-relative p-0"
              size="sm"
              variant="link"
            >
              <FontAwesomeIcon icon="filter" className="fs-9 text-700" />
            </Button>
          </Col>
          <Col>
            <Form className="position-relative">
              <Form.Control
                type="search"
                placeholder="Search..."
                size="sm"
                aria-label="Search"
                className="rounded search-input ps-4"
              />
              <FontAwesomeIcon
                icon="search"
                className="fs-10 text-400 position-absolute text-400 start-0 top-50 translate-middle-y ms-2"
              />
            </Form>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

GigHeader.propTypes = {
  layout: PropTypes.string,
  isAsc: PropTypes.bool,
  setIsAsc: PropTypes.func,
  setSortBy: PropTypes.func,
  setShowFilterOffcanvas: PropTypes.func,
  searchedText: PropTypes.string,
  handleSearch: PropTypes.func,
};

export default GigHeader;
