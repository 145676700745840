import React, { useState } from "react";
import { Field, FieldArray } from "formik";
import { Card, Col, Form, Row } from "react-bootstrap";
import CustomDateInput from "../../../../components/common/CustomDateInput";
import IconButton from "../../../../components/common/IconButton";
import DatePicker from "react-datepicker";

const ExperienceForm = ({ values, setFieldValue, errors }) => {
  const [dateTo, setDateTo] = useState(null);
  const [dateFrom, setDateFrom] = useState(null);
  return (
    <FieldArray
      name={"experiences"}
      render={(arrayHelpers) => (
        <div>
          <h5>Experience</h5>
          {values?.experiences && values.experiences.length > 0 ? (
            values.experiences.map((experience, index) => (
              <Card
                key={index}
                variant="outlined"
                style={{ marginBottom: "1rem" }}
              >
                <Card.Header>
                  <h6>Experience {index + 1}</h6>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col lg={6}>
                      <Form.Group>
                        <Form.Label>
                          Company <span className={"text-danger"}>*</span>
                        </Form.Label>
                        <Field name={`experiences.${index}.company`}>
                          {({ field }) => (
                            <Form.Control
                              type="text"
                              value={field.value}
                              size={"sm"}
                              onChange={(e) =>
                                setFieldValue(field.name, e.target.value)
                              }
                            />
                          )}
                        </Field>
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group>
                        <Form.Label>
                          Role <span className={"text-danger"}>*</span>
                        </Form.Label>
                        <Field name={`experiences.${index}.role`}>
                          {({ field }) => (
                            <Form.Control
                              type="text"
                              value={field.value}
                              size={"sm"}
                              onChange={(e) =>
                                setFieldValue(field.name, e.target.value)
                              }
                            />
                          )}
                        </Field>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>
                          From <span className={"text-danger"}>*</span>
                        </Form.Label>
                        <Field name={`experiences.${index}.date_from`}>
                          {({ field }) => (
                            <DatePicker
                              selected={dateFrom}
                              onChange={(date) => {
                                setDateFrom(date);
                                setFieldValue(field.name, date);
                              }}
                              customInput={<CustomDateInput />}
                            />
                          )}
                        </Field>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Label>
                          To <span className={"text-danger"}>*</span>
                        </Form.Label>
                        <Field name={`experiences.${index}.date_to`}>
                          {({ field }) => (
                            <DatePicker
                              selected={dateTo}
                              onChange={(date) => {
                                setDateTo(date);
                                setFieldValue(field.name, date);
                              }}
                              customInput={<CustomDateInput />}
                            />
                          )}
                        </Field>
                      </Form.Group>
                    </Col>
                  </Row>

                  <IconButton
                    variant="danger"
                    size="sm"
                    onClick={() => arrayHelpers.remove(index)}
                    aria-label="Delete experience"
                    style={{ marginTop: "1rem" }}
                    icon={"trash-alt"}
                  ></IconButton>
                </Card.Body>
              </Card>
            ))
          ) : (
            <p>No experience added yet.</p>
          )}
          <IconButton
            icon={"plus"}
            variant="contained"
            color="primary"
            size="sm"
            onClick={() =>
              arrayHelpers.push({
                company: "",
                date_from: "",
                date_to: "",
                role: "",
              })
            }
          >
            Add Experience
          </IconButton>
        </div>
      )}
    />
  );
};

export default ExperienceForm;
