import React from 'react';
import ForgetPasswordForm from '../ForgetPasswordForm';
import AuthCardLayout from 'layouts/AuthCardLayout';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const ForgetPassword = ({ t, i18n }) => {
  return (
    <AuthCardLayout>
      <h4 className="mb-0"> Forgot your password?</h4>
      <p className="mb-0">Enter your email and we'll send you a reset link.</p>
      <ForgetPasswordForm layout="card" t={t} i18n={i18n} />
    </AuthCardLayout>
  );
};

ForgetPassword.propTypes = {
  t: PropTypes.func.isRequired,
  i18n: PropTypes.object.isRequired
};

export default withTranslation(['authentication', 'common'])(ForgetPassword);
