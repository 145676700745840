import React from "react";
import { createRoot } from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import AppProvider from "providers/AppProvider";
import { router } from "routes";
import "helpers/initFA";
import i18next from "i18next";
import { I18nextProvider, initReactI18next } from "react-i18next";
import { getItemFromStore } from "./helpers/utils";
import common from "./translations/common.json";
import user from "./translations/accounts/user.json";
import group from "./translations/accounts/group.json";
import trainer from "./translations/trainer.json";
import { PermissionsProvider } from "./providers/PermissionsProvider";
import "@mantine/core/styles.css";
import { MantineProvider } from "@mantine/core";

const container = document.getElementById("main");
const root = createRoot(container);

i18next.use(initReactI18next).init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: getItemFromStore("lang", "en"),
  resources: {
    en: {
      common: common.en,
      users: user.en,
      groups: group.en,
      trainer: trainer.en,
    },
    fr: {
      common: common.fr,
      users: user.fr,
      groups: group.fr,
      trainer: trainer.fr,
    },
    ar: {
      common: common.ar,
      users: user.ar,
      groups: group.ar,
      trainer: trainer.ar,
    },
  },
});

root.render(
  <React.StrictMode>
    <AppProvider>
      <MantineProvider>
        <PermissionsProvider>
          <I18nextProvider i18n={i18next}>
            <RouterProvider router={router} />
          </I18nextProvider>
        </PermissionsProvider>
      </MantineProvider>
    </AppProvider>
  </React.StrictMode>
);
