import React, { createContext, useContext } from 'react';
import { useAppContext } from './AppProvider';
import PropTypes from 'prop-types';

const PermissionsContext = createContext({});

export const PermissionsProvider = ({ children }) => {
  const {
    config: { permissions }
  } = useAppContext();

  const hasPermission = permission => {
    return permissions?.includes(permission);
  };

  const hasPermissions = perms => {
    return perms?.some(name => permissions?.includes(name));
  };

  return (
    <PermissionsContext.Provider
      value={{ hasPermission, hasPermissions, permissions }}
    >
      {children}
    </PermissionsContext.Provider>
  );
};

PermissionsProvider.propTypes = { children: PropTypes.node.isRequired };

export const usePermissions = () => useContext(PermissionsContext);
