import React, { useEffect, useState } from "react";
import { Card, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Avatar } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../../../providers/AppProvider";
import { api } from "../../../utils/api";

export const HeaderLanguages = () => {
  const {
    config: { lang },
    setConfig,
  } = useAppContext();

  const [isOpen, setIsOpen] = useState(false);
  const [activeLanguage, setActiveLanguage] = useState(lang);
  const [, i18n] = useTranslation("common");
  const languages = ["ar", "en", "fr"];

  useEffect(() => {
    setActiveLanguage(lang);
  }, [lang]);

  useEffect(() => {
    if (lang !== "ar") setConfig("isRTL", false);
    else setConfig("isRTL", true);
    // eslint-disable-next-line
  }, [lang]);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Dropdown
      navbar={true}
      className={"px-2"}
      as="li"
      show={isOpen}
      onToggle={handleToggle}
    >
      <Dropdown.Toggle bsPrefix="toggle" as={Link} to="#!">
        <Avatar
          size={24}
          src={require(`../../../assets/img/languages/${activeLanguage}.svg`)}
        />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-menu-end dropdown-caret dropdown-caret-bg">
        <Card
          className="shadow-none border-color-black-40 border-radius globalseo-exclude"
          style={{ maxWidth: "10rem" }}
        >
          {languages.map(
            (item, index) =>
              item !== activeLanguage && (
                <Dropdown.Item
                  key={index}
                  onClick={() => {
                    setConfig("lang", item);
                    api.defaults.headers.common["Accept-Language"] = item;
                    i18n.changeLanguage(item);
                  }}
                  style={{ height: "3rem" }}
                >
                  <div
                    className={
                      "d-flex justify-content-between align-self-center"
                    }
                  >
                    <Avatar
                      size={24}
                      src={require(`../../../assets/img/languages/${item}.svg`)}
                    />
                    <span className="languages-text text-color-black-80 text-color-dark-30 text-uppercase ml-4">
                      {item}
                    </span>
                  </div>
                </Dropdown.Item>
              )
          )}
        </Card>
      </Dropdown.Menu>
    </Dropdown>
  );
};
