import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Button, Form, Spinner } from 'react-bootstrap';
import classNames from 'classnames';
import CSRFToken from '../../helpers/CSRFToken';
import useQuery from '../../hooks/useQuery';
import FormError from '../errors/FormError';
import { api } from '../../utils/api';
import Flex from '../../components/common/Flex';
import { useNavigate } from 'react-router-dom';
import paths from '../../routes/paths';

const PasswordResetForm = ({ hasLabel, t }) => {
  let query = useQuery();
  // State
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    uid: query.get('uid'),
    token: query.get('token'),
    new_password1: '',
    new_password2: ''
  });

  const navigate = useNavigate();

  // Handler
  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    await api
      .post('/dj-rest-auth/password/reset/confirm/', formData)
      .then(res => {
        toast.success(res?.data?.detail, {
          theme: 'colored'
        });
        navigate(paths.splitLogin);
      })
      .catch(error => {
        toast.error(`${t('error', { ns: 'common' })}`, {
          theme: 'colored'
        });
        setErrors(error?.response?.data);
      });
    setLoading(false);
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return loading ? (
    <Flex justifyContent="center" className="p-2 mb-2">
      <Spinner animation={'border'} variant={'primary'} />
    </Flex>
  ) : (
    <Form
      className={classNames('mt-3', { 'text-left': hasLabel })}
      onSubmit={handleSubmit}
    >
      <CSRFToken />
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>{t('fields.password')}</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? t('fields.password') : ''}
          value={formData.new_password1}
          name="new_password1"
          onChange={handleFieldChange}
          type="password"
        />
        <FormError error={errors?.new_password1} />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>{t('fields.passwordConfirm')}</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? t('fields.passwordConfirm') : ''}
          value={formData.new_password2}
          name="new_password2"
          onChange={handleFieldChange}
          type="password"
        />
        <FormError error={errors?.new_password2} />
      </Form.Group>

      <Button
        type="submit"
        className="w-100"
        disabled={!formData.new_password1 || !formData.new_password2}
      >
        {t('reset.btnText')}
      </Button>
    </Form>
  );
};

PasswordResetForm.propTypes = {
  hasLabel: PropTypes.bool,
  t: PropTypes.func
};

export default PasswordResetForm;
