import React, { useEffect, useRef, useState } from "react";
import { Card, Col, Form, Offcanvas, Row, Spinner } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import CourseGrid from "./CourseGrid";
import CourseList from "./CourseList";
import CourseHeader from "./CourseHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flex from "components/common/Flex";
import CourseFilters from "./CourseFilters";
import { useBreakpoints } from "hooks/useBreakpoints";
import { useAppContext } from "providers/AppProvider";
import useQuery from "../../../hooks/useQuery";
import { api } from "../../../utils/api";
import AdvanceTablePagination from "../../../components/common/advance-table/AdvanceTablePagination";

const Courses = () => {
  const [courses, setCourses] = useState([]);
  const [count, setCount] = useState(0);
  const [length, setLength] = useState(0);
  const [showFilterOffcanvas, setShowFilterOffcanvas] = useState(false);
  const [loading, setLoading] = useState(false);
  const [coursePerPage, setCoursePerPage] = useState(6);
  const navigate = useNavigate();
  const { breakpoints } = useBreakpoints();
  const { courseLayout } = useParams();

  const {
    config: { isNavbarVerticalCollapsed },
    setConfig,
  } = useAppContext();
  const coursesNavbarVerticalCollapsed = useRef(isNavbarVerticalCollapsed);
  const query = useQuery();

  const layout = courseLayout.split(/-/)[1];
  const isList = layout === "list";
  const isGrid = layout === "grid";

  const getCourses = async (q) => {
    setLoading(true);
    await api.get(`/course/course/?${q.toString()}`).then((res) => {
      setCourses(res?.data?.results);
      setCount(res?.data?.count);
    });
    setLoading(false);
  };

  useEffect(() => {
    getCourses(query);
    setLength(courses?.length);
  }, []);

  useEffect(() => {
    isList || isGrid || navigate("/errors/404");
  }, []);

  useEffect(() => {
    setConfig("isNavbarVerticalCollapsed", true);

    return () => {
      setConfig(
        "isNavbarVerticalCollapsed",
        coursesNavbarVerticalCollapsed.current
      );
    };
  }, []);

  return (
    <>
      <Row className="g-3">
        {breakpoints.up("xl") && (
          <Col xl={3}>
            <CourseFilters />
          </Col>
        )}
        {loading ? (
          <Flex justifyContent="center" className="p-2 mb-2">
            <Spinner animation={"border"} variant={"primary"} />
          </Flex>
        ) : (
          <Col xl={9}>
            {/* Courses Header */}
            <CourseHeader
              layout={layout}
              setShowFilterOffcanvas={setShowFilterOffcanvas}
            />
            {/* Courses */}
            <Row className="mb-3 g-3">
              {courses?.length > 0 ? (
                courses?.map((course) =>
                  layout === "list" ? (
                    <Col key={course.id} xs={12}>
                      <CourseList course={course} />
                    </Col>
                  ) : (
                    <Col key={course.id} md={6} xxl={4}>
                      <CourseGrid course={course} />
                    </Col>
                  )
                )
              ) : (
                <Card className="bg-transparent shadow-none">
                  <Card.Body className="border-2 border-dashed border-400 border rounded text-center py-5">
                    <div className="fs-10">
                      <FontAwesomeIcon
                        icon="exclamation-triangle"
                        className="fs-3 mb-3"
                      />
                      <h5>No Courses Found!</h5>
                      <p className="mb-0">
                        Your search did not match any Courses. Please try again.
                      </p>
                    </div>
                  </Card.Body>
                </Card>
              )}
            </Row>
            {/* Course pagination */}
            {courses?.length > 0 && (
              <Card>
                <Card.Body>
                  <Row className="g-3 flex-column flex-sm-row flex-center justify-content-sm-between">
                    <Col
                      xs="auto"
                      as={Flex}
                      alignItems="center"
                      className="d-none d-sm-flex"
                    >
                      <small className="d-none d-sm-block me-2">Show:</small>
                      <Form.Select
                        size="sm"
                        value={coursePerPage}
                        onChange={({ target }) => {
                          setCoursePerPage(target.value);
                        }}
                        style={{ maxWidth: "4.875rem" }}
                      >
                        <option value={2}>2</option>
                        <option value={4}>4</option>
                        <option value={6}>6</option>
                        <option value={count}>All</option>
                      </Form.Select>
                    </Col>
                    <AdvanceTablePagination
                      length={length}
                      fetch={getCourses}
                      itemsPerPage={coursePerPage}
                      query={query}
                      count={count}
                    />
                  </Row>
                </Card.Body>
              </Card>
            )}
          </Col>
        )}
      </Row>
      {breakpoints.down("xl") && (
        <Offcanvas
          show={showFilterOffcanvas}
          onHide={() => setShowFilterOffcanvas(false)}
          placement="start"
          className="offcanvas offcanvas-filter-sidebar"
        >
          <CourseFilters isOffcanvas={true} setShow={setShowFilterOffcanvas} />
        </Offcanvas>
      )}
    </>
  );
};

export default Courses;
