import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import Flex from "../../components/common/Flex";
import { Link } from "react-router-dom";
import paths from "../../routes/paths";

const GigGrid = ({ gig }) => {
  const { id, title, trainer, price, oldPrice } = gig;

  return (
    <>
      <Card className="h-100 overflow-hidden">
        <Card.Body
          as={Flex}
          direction="column"
          justifyContent="between"
          className="p-0"
        >
          <div>
            <div className="p-3">
              <h5 className="fs-9 mb-2">
                <Link to={paths.courseDetails(id)} className="text-1100">
                  {title}
                </Link>
              </h5>
              <h5 className="fs-9">
                <Link to={paths.trainerProfile}>{trainer}</Link>
              </h5>
            </div>
          </div>
          <Row className="g-0 mb-3 align-items-end">
            <Col className="ps-3">
              <h4 className="fs-8 text-warning d-flex align-items-center">
                <span>${price}</span>
                {oldPrice && (
                  <del className="ms-2 fs-10 text-700">${oldPrice}</del>
                )}
              </h4>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default GigGrid;
