import { withPermission } from "../../../../helpers/utils";
import { withTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import useQuery from "../../../../hooks/useQuery";
import { useNavigate, useParams } from "react-router-dom";
import { getPermissions } from "../../actions/GroupsPermissions";
import { Card, Form, Spinner } from "react-bootstrap";
import Flex from "../../../../components/common/Flex";
import { Field, Formik } from "formik";
import CSRFToken from "../../../../helpers/CSRFToken";
import FormError from "../../../errors/FormError";
import Select from "react-select";
import IconButton from "../../../../components/common/IconButton";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { api } from "../../../../utils/api";
import { toast } from "react-toastify";
import paths from "../../../../routes/paths";

const GroupAddEdit = ({ t }) => {
  const [loading, setLoading] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState(null);
  const [page, setPage] = useState(1);
  const [formData, setFormData] = useState({
    name: "",
    permissions: [],
  });
  const [errors, setErrors] = useState({});

  const navigate = useNavigate();
  const { id } = useParams();
  const query = useQuery();

  const fetchGroup = async () => {
    setLoading(true);
    await api
      .get(`/account/group/${id}/`)
      .then(({ data }) => {
        setFormData(data);
        setSelectedPermissions(
          data?.permissions?.map((perm, index) => ({
            label: data?.permission_names[index],
            value: perm,
          }))
        );
      })
      .catch((err) => {
        if (err?.response?.status === 404) {
          navigate(paths.error404);
        }
      });
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    query.set("page_size", "50");
    query.set("page", page.toString());
    getPermissions(query)
      .then((res) => setPermissions([...permissions, ...res?.results]))
      .catch(() => {});
    setLoading(false);
    // eslint-disable-next-line
  }, [page]);

  useEffect(() => {
    if (id) fetchGroup();
    // eslint-disable-next-line
  }, []);

  let permissionOptions = permissions.map((permission) => ({
    label: permission.name,
    value: permission.id,
  }));

  const handleSubmit = async (e, values) => {
    e.preventDefault();
    setLoading(true);
    if (id) {
      await api
        .patch(`/account/group/${id}/`, values)
        .then(() => {
          toast.success("Group successfully updated.", { theme: "colored" });
          navigate(paths.groupList);
        })
        .catch((error) => {
          toast.error("An error has occurred.", { theme: "colored" });
          setFormData(values);
          setErrors(error?.response?.data);
        });
    } else {
      await api
        .post("/account/group/", values)
        .then(() => {
          toast.success(`${t("title")} ${t("addSuccess", { ns: "common" })}`, {
            theme: "colored",
          });
          navigate(paths.groupList);
        })
        .catch((err) => {
          setFormData(values);
          setErrors(err?.response?.data);
          toast.error(`${t("error", { ns: "common" })}`, { theme: "colored" });
        });
    }
    setLoading(false);
  };

  return loading ? (
    <Flex justifyContent="center" alignItems={"center"} className="p-2 mb-2">
      <Spinner animation={"border"} variant={"primary"} />
    </Flex>
  ) : (
    <Formik initialValues={formData} onSubmit={(values) => console.log(values)}>
      {({ values, setFieldValue }) => (
        <Form>
          <CSRFToken />
          <Card>
            <Card.Header>
              <h5 className="mb-0 text-muted">{t("groupSettings")}</h5>
            </Card.Header>
            <Card.Body>
              <Form.Group>
                <Form.Label>
                  {t("fields.name")}: <span className={"text-danger"}>*</span>
                </Form.Label>
                <Field name={"name"}>
                  {({ field }) => (
                    <Form.Control
                      name={"name"}
                      value={field.value}
                      onChange={(e) =>
                        setFieldValue(field.name, e.target.value)
                      }
                      placeholder={t("fields.name")}
                    />
                  )}
                </Field>
                <FormError error={errors.name} />
              </Form.Group>
              <Form.Group>
                <Form.Label>{t("fields.permissions")}:</Form.Label>
                <Field name={"permissions"}>
                  {({ field }) => (
                    <Select
                      options={permissionOptions}
                      placeholder={`${t("select", { ns: "common" })} ${t(
                        "fields.permissions"
                      )}`}
                      isMulti
                      classNamePrefix="react-select"
                      value={selectedPermissions}
                      onChange={(selectedOptions) => {
                        setSelectedPermissions(selectedOptions);
                        setFieldValue(
                          field.name,
                          selectedOptions.map((op) => op.value)
                        );
                      }}
                      onMenuScrollToBottom={(event) => {
                        setPage((prevState) => prevState + 1);
                      }}
                      onInputChange={(newValue) => {
                        query.set("search", newValue);
                        getPermissions(query)
                          .then((res) => setPermissions(res?.results))
                          .catch(() => {});
                      }}
                    />
                  )}
                </Field>
                <FormError error={errors.permissions} />
              </Form.Group>
            </Card.Body>
            <Card.Footer>
              <Flex
                justifyContent={"between"}
                alignItems={"center"}
                wrap={"wrap"}
              >
                <p className={"text-danger"}>
                  * {t("mandatory", { ns: "common" })}
                </p>
                <IconButton
                  icon={faSave}
                  onClick={(e) => handleSubmit(e, values)}
                >
                  <span className="d-none d-sm-inline-block ms-1">
                    {t("save", { ns: "common" })}
                  </span>
                </IconButton>
              </Flex>
            </Card.Footer>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export default withPermission(
  withTranslation(["groups", "common"])(GroupAddEdit),
  "auth.add_group"
);
