import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Modal,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flex from "components/common/Flex";
import MultiSelect from "components/common/MultiSelect";
import { Field } from "formik";
import { api } from "../../../../utils/api";
import CSRFToken from "../../../../helpers/CSRFToken";
import TinymceEditor from "../../../../components/common/TinymceEditor";
import { toast } from "react-toastify";

const CourseGoals = ({ values, setFieldValue, errors, index }) => {
  const [features, setFeatures] = useState([]);
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [featureModalOpen, setFeatureModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [featureFormData, setFeatureFormData] = useState({
    title: "",
    icon: "",
    description: "",
  });
  const [goals, setGoals] = useState(values?.contents);
  const [goal, setGoal] = useState("");
  const [editingGoal, setEditingGoal] = useState({});
  const [isEditing, setIsEditing] = useState(false);

  const fetchFeatures = async () => {
    await api
      .get("/course/coursefeature/")
      .then(({ data }) => setFeatures(data?.results));
  };

  useEffect(() => {
    fetchFeatures();
    // eslint-disable-next-line
  }, []);

  const handleGoalSubmit = () => {
    if (!isEditing) {
      setGoals([...goals, { content: goal }]);
      setGoal("");
    } else {
      const updatedGoals = goals.map((goal) => {
        if (goal.content === editingGoal.content) {
          return {
            ...goal,
            content: editingGoal.goal,
          };
        }
        return goal;
      });
      setGoals(updatedGoals);
      setEditingGoal({});
      setIsEditing(false);
    }
  };

  const handleFeatureSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    await api
      .post("/course/coursefeature/", featureFormData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        toast.success("Feature successfully added.", { theme: "colored" });
        setSelectedFeatures((prevState) => [
          ...prevState,
          { label: res?.data?.title, value: res?.data?.id },
        ]);
        setFeatureModalOpen(!featureModalOpen);
      })
      .catch((err) => {
        toast.error("An error has occurred.", { theme: "colored" });
      });
    setLoading(false);
  };

  const handleFeatureFormChange = (e) => {
    setFeatureFormData({ ...featureFormData, [e.target.name]: e.target.value });
  };

  const handleChange = (value, setFieldValue) => {
    isEditing
      ? setEditingGoal({
          ...editingGoal,
          content: value,
        })
      : setGoal(value);
    setFieldValue(`contents.${index}.content`, value);
  };

  return (
    <Card className="mb-3 mb-lg-0">
      <Card.Header as="h5" className="bg-body-tertiary">
        Course Goals and Key features
      </Card.Header>
      <Card.Body>
        <Form.Label htmlFor="courseGoal">
          Course Goals<span className="text-danger">*</span>
        </Form.Label>
        <ul className="list-unstyled mb-0">
          {goals.map((item) => (
            <li key={item.content}>
              <Flex
                alignItems="center"
                className="hover-actions-trigger py-3 border-top border-300"
              >
                <FontAwesomeIcon
                  icon="grip-lines"
                  className="fs-11 text-secondary me-3"
                />
                <p className="fs-10 mb-0 me-6">{item.content}</p>
                <div className="hover-actions end-0 top-50 translate-middle-y">
                  <OverlayTrigger
                    overlay={
                      <Tooltip style={{ position: "fixed" }} id="editTooltip">
                        Edit
                      </Tooltip>
                    }
                  >
                    <Button
                      variant="link"
                      type="button"
                      onClick={() => {
                        setEditingGoal(item);
                        setIsEditing(true);
                      }}
                      className="d-flex align-items-center fs-10 text-700 p-0 me-3"
                    >
                      <FontAwesomeIcon icon="pencil-alt" />
                    </Button>
                  </OverlayTrigger>
                  <OverlayTrigger
                    overlay={
                      <Tooltip style={{ position: "fixed" }} id="removeTooltip">
                        Remove
                      </Tooltip>
                    }
                  >
                    <Button
                      variant="falcon-default"
                      type="button"
                      size="sm"
                      onClick={() =>
                        setGoals(
                          goals.filter(
                            ({ content }) => content !== item.content
                          )
                        )
                      }
                      className="icon-item icon-item-sm rounded-3 fs-11"
                    >
                      <FontAwesomeIcon icon="times" />
                    </Button>
                  </OverlayTrigger>
                </div>
              </Flex>
            </li>
          ))}
        </ul>
        <Form.Group controlId="courseGoal" className="position-relative mb-4">
          <Field name={`contents.${index}.text`}>
            {({ field }) => (
              <Form.Control
                type="text"
                name="courseGoal"
                required
                value={!isEditing ? goal : editingGoal.content}
                className="pe-4"
                onChange={(e) => handleChange(e.target.value, setFieldValue)}
                placeholder="Add another goal..."
              />
            )}
          </Field>
          <div className="position-absolute end-0 top-50 translate-middle">
            <Button
              variant="link"
              onClick={handleGoalSubmit}
              type="button"
              className="d-flex align-items-center fs-10 text-700 p-0 me-1"
            >
              <FontAwesomeIcon icon="arrow-right" />
            </Button>
          </div>
        </Form.Group>
        <Form.Group>
          <Flex alignItems="center" justifyContent="between">
            <Form.Label>
              Key features<span className="text-danger">*</span>
            </Form.Label>
            <Button
              size="sm"
              variant="link"
              className="p-0"
              onClick={() => setFeatureModalOpen(!featureModalOpen)}
            >
              Add new
            </Button>
          </Flex>
          <Field name="features">
            {({ field }) => (
              <MultiSelect
                {...field}
                menuPlacement="top"
                closeMenuOnSelect={false}
                isMulti
                value={selectedFeatures}
                options={features?.map((feature) => ({
                  label: feature?.title,
                  value: feature?.id,
                }))}
                onChange={(newValue) => {
                  setSelectedFeatures(newValue);
                  setFieldValue(
                    field.name,
                    newValue.map((f) => f.value)
                  );
                }}
                placeholder="Select multiple features..."
              />
            )}
          </Field>
        </Form.Group>
      </Card.Body>
      <Modal
        size={"lg"}
        centered
        show={featureModalOpen}
        onHide={() => setFeatureModalOpen(!featureModalOpen)}
      >
        <Modal.Header closeButton>
          <h5>Add new feature</h5>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <Flex justifyContent="center" className="p-2 mb-2">
              <Spinner animation={"border"} variant={"primary"} />
            </Flex>
          ) : (
            <Form>
              <CSRFToken />
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>
                      Title <span className={"text-danger"}>*</span>
                    </Form.Label>
                    <Form.Control
                      type={"text"}
                      name={"title"}
                      value={featureFormData.title}
                      onChange={handleFeatureFormChange}
                      placeholder={"Title"}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>
                      Icon <span className={"text-danger"}>*</span>
                    </Form.Label>
                    <Form.Control
                      type={"file"}
                      name={"icon"}
                      onChange={(e) =>
                        setFeatureFormData({
                          ...featureFormData,
                          icon: e.target.files[0],
                        })
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group>
                <Form.Label>
                  Description <span className={"text-danger"}>*</span>
                </Form.Label>
                <div className="create-course-description-textarea">
                  <TinymceEditor
                    height="13.438rem"
                    value={featureFormData.description}
                    handleChange={(newValue) => {
                      setFeatureFormData({
                        ...featureFormData,
                        description: newValue,
                      });
                    }}
                  />
                </div>
              </Form.Group>
            </Form>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setFeatureModalOpen(!featureModalOpen)}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={(e) => {
              handleFeatureSubmit(e);
            }}
          >
            Save <FontAwesomeIcon icon="paper-plane" className="ms-2" />
          </Button>
        </Modal.Footer>
      </Modal>
    </Card>
  );
};

export default CourseGoals;
