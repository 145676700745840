import React from 'react';
import PropTypes from 'prop-types';
import createMarkup from 'helpers/createMarkup';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { version } from 'config';
import paths from '../../routes/paths';
import { useTranslation } from 'react-i18next';
import Flex from '../../components/common/Flex';
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faYoutube
} from '@fortawesome/free-brands-svg-icons';

const FooterTitle = ({ children }) => (
  <h5 className="text-uppercase text-white opacity-85 mb-3">{children}</h5>
);

FooterTitle.propTypes = { children: PropTypes.node.isRequired };

const FooterList = ({ list }) => (
  <ul className="list-unstyled">
    {list.map(({ title, to }, index) => (
      <li className="mb-1" key={index}>
        <Link className="text-600" to={to}>
          {title}
        </Link>
      </li>
    ))}
  </ul>
);

FooterList.propTypes = { list: PropTypes.array.isRequired };

const FooterBlogList = ({ list }) => (
  <ul className="list-unstyled">
    {list.map((blog, index) => (
      <li key={index}>
        <h5 className="fs-0 mb-0">
          <Link className="text-600" to="#!">
            {blog.title}
          </Link>
        </h5>
        <p className="text-600 opacity-50">
          {blog.date} &bull; {blog.read} read{' '}
          {blog.star && (
            <span dangerouslySetInnerHTML={createMarkup('&starf;')} />
          )}
        </p>
      </li>
    ))}
  </ul>
);

FooterBlogList.propTypes = { list: PropTypes.array.isRequired };

const FooterStandard = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  };
  const { t } = useTranslation('footer');

  return (
    <>
      <section className=" bg-light py-0 text-center fs--1 light">
        <div
          className="position-absolute btn-back-to-top cursor-pointer bg-light mt-8 z-index-1 "
          onClick={scrollToTop}
        >
          <FontAwesomeIcon
            icon="chevron-up"
            transform="rotate-45"
            className="text-600"
          />
        </div>
        <hr className="my-0 border-600 opacity-25" />
        <div className="container py-3">
          <Row className="justify-content-between">
            <Col xs={12} sm="auto">
              <p className="mb-0 text-600">
                {t('main.thankYou')} Nexameet <br className="d-sm-none" />
                &copy; {new Date().getFullYear()}
              </p>
            </Col>
            <Col xs={12} sm="auto">
              <Flex
                justifyContent={'between'}
                alignItems={'center'}
                wrap={'wrap'}
              >
                <div className={'d-flex justify-content-between me-3'}>
                  <a
                    href={
                      'https://www.facebook.com/profile.php?id=61561153903337'
                    }
                    target={'_blank'}
                    rel={'noreferrer'}
                  >
                    <FontAwesomeIcon
                      icon={faFacebook}
                      size={'2x'}
                      className={'me-2'}
                    />
                  </a>
                  <a
                    href={'https://www.instagram.com/nexameet/'}
                    target={'_blank'}
                    rel={'noreferrer'}
                  >
                    <FontAwesomeIcon
                      icon={faInstagram}
                      size={'2x'}
                      className={'me-2'}
                    />
                  </a>
                  <a
                    href={'https://www.linkedin.com/company/nexameet/'}
                    target={'_blank'}
                    rel={'noreferrer'}
                  >
                    <FontAwesomeIcon
                      icon={faLinkedin}
                      size={'2x'}
                      className={'me-2'}
                    />
                  </a>
                  <a
                    href={
                      'https://www.youtube.com/channel/UCyYXGQcuTbS55vAL1SEJbMA'
                    }
                    target={'_blank'}
                    rel={'noreferrer'}
                  >
                    <FontAwesomeIcon
                      icon={faYoutube}
                      size={'2x'}
                      className={'me-2'}
                    />
                  </a>
                </div>
                <p className="mb-0 text-600">
                  <Link to={paths.privacyPolicy}>
                    {t('main.privacyPolicy')}
                  </Link>{' '}
                  -{' '}
                  <Link to={paths.termsConditions}>
                    {t('main.termsConditions')}
                  </Link>{' '}
                  - v{version}
                </p>
              </Flex>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
};

export default FooterStandard;
