import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
import { Field, FieldArray } from "formik";
import TinymceEditor from "../../../components/common/TinymceEditor";
import Select from "react-select";
import { api } from "../../../utils/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormErrors from "../../errors/FormErrors";
import IconButton from "../../../components/common/IconButton";
import DatePicker from "react-datepicker";
import CustomDateInput from "../../../components/common/CustomDateInput";
import { useDropzone } from "react-dropzone";
import CardDropdown from "../../../components/common/CardDropdown";
import { getSize } from "../../../helpers/utils";
import Flex from "../../../components/common/Flex";
import cloudUpload from "assets/img/icons/cloud-upload.svg";
import { v4 as uuid } from "uuid";

const TeacherGigForm = ({ errors, setFieldValue, values }) => {
  const [subjects, setSubjects] = useState([]);
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [files, setFiles] = useState(values?.resources || []);

  const { getRootProps, getInputProps } = useDropzone({
    multiple: true,
    onDrop: (acceptedFiles) => {
      const fileReaders = acceptedFiles.map((file) => {
        const reader = new FileReader();
        return new Promise((resolve, reject) => {
          reader.onloadend = () => {
            resolve({
              id: uuid(),
              file: {
                base64: reader.result,
                path: file.path,
                size: file.size,
                preview: URL.createObjectURL(file),
              },
              data: {
                base64: reader.result,
                path: file.path,
                size: file.size,
                preview: URL.createObjectURL(file),
              },
            });
          };
          reader.onerror = reject;
          reader.readAsDataURL(file);
        });
      });
      Promise.all(fileReaders)
        .then((newFiles) => {
          setFiles((prevFiles) => {
            const updatedFiles = [...prevFiles, ...newFiles];
            setFieldValue("resources", updatedFiles);
            return updatedFiles;
          });
        })
        .catch((error) => {
          console.error("Error reading files: ", error);
        });
    },
  });

  const fetchSubjects = async () => {
    await api.get("/subject/").then((response) => {
      setSubjects(response?.data?.results);
    });
  };

  useEffect(() => {
    fetchSubjects();
  }, []);

  const subjectOptions = subjects?.map((s) => ({ label: s.name, value: s.id }));

  const handleRemove = (path) => {
    setFiles((prevFiles) => {
      const updatedFiles = prevFiles.filter(
        (file) => file?.data?.path !== path
      );
      setFieldValue("resources", updatedFiles);
      return updatedFiles;
    });
  };

  return (
    <Fragment>
      <Row>
        <Col lg={6}>
          <Card>
            <Card.Header>
              <h5>Basic Information</h5>
            </Card.Header>
            <Card.Body>
              <Form.Group>
                <Form.Label>
                  Title <span className={"text-danger"}>*</span>
                </Form.Label>
                <Field name={"title"}>
                  {({ field }) => (
                    <Form.Control
                      type={"text"}
                      placeholder={"Title"}
                      value={field.value}
                      onChange={(e) =>
                        setFieldValue(field.name, e.target.value)
                      }
                    />
                  )}
                </Field>
              </Form.Group>
              <Form.Group>
                <Form.Label>
                  Description<span className="text-danger">*</span>
                </Form.Label>
                <Field name={"description"}>
                  {({ field }) => (
                    <div className="create-course-description-textarea">
                      <TinymceEditor
                        height="13.438rem"
                        value={field.value}
                        handleChange={(newValue) => {
                          setFieldValue(field.name, newValue);
                        }}
                      />
                    </div>
                  )}
                </Field>
              </Form.Group>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>
                      Hourly Rate <span className={"text-danger"}>*</span>
                    </Form.Label>
                    <Field name={"hourly_rate"}>
                      {({ field }) => (
                        <InputGroup className="mb-3">
                          <InputGroup.Text>$</InputGroup.Text>
                          <Form.Control
                            type="number"
                            placeholder={"Hourly Rate"}
                            value={field.value}
                            onChange={(e) =>
                              setFieldValue(field.name, e.target.value)
                            }
                          />
                          <InputGroup.Text>/hr</InputGroup.Text>
                        </InputGroup>
                      )}
                    </Field>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>
                      Subjects <span className={"text-danger"}>*</span>
                    </Form.Label>
                    <Field name={"subjects"}>
                      {({ field }) => (
                        <Select
                          options={subjectOptions}
                          value={selectedSubjects}
                          isMulti
                          classNamePrefix={"react-select"}
                          onChange={(newValue) => {
                            setSelectedSubjects(newValue);
                            setFieldValue(
                              field.name,
                              newValue.map((s) => s.value)
                            );
                          }}
                          placeholder={"Select Subjects"}
                        />
                      )}
                    </Field>
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Card className={"mt-3"}>
            <Card.Header>
              <h5>Resources</h5>
            </Card.Header>
            <Card.Body>
              <div {...getRootProps({ className: "dropzone-area py-6" })}>
                <input name="uploadedFiles" {...getInputProps()} />
                <Flex justifyContent="center">
                  <img src={cloudUpload} alt="" width={25} className="me-2" />
                  <p className="fs-9 mb-0 text-700">Drop your images here</p>
                </Flex>
              </div>
              <div>
                {files?.map((file) => (
                  <Flex
                    alignItems="center"
                    className="py-3 border-bottom btn-reveal-trigger"
                    key={file?.data?.path}
                  >
                    <img
                      className="rounded"
                      width={40}
                      height={40}
                      src={file?.data?.preview || file?.image_url}
                      alt={file?.data?.path || file?.image_url}
                    />

                    <Flex
                      justifyContent="between"
                      alignItems="center"
                      className="ms-3 flex-1"
                    >
                      <div>
                        <h6>{file?.data?.path}</h6>
                        <Flex className="position-relative" alignItems="center">
                          <p className="mb-0 fs-10 text-400 line-height-1">
                            <strong>{getSize(file?.data?.size)}</strong>
                          </p>
                        </Flex>
                      </div>
                    </Flex>
                    <CardDropdown>
                      <div className="py-2">
                        <Dropdown.Item
                          className="text-danger"
                          onClick={() => handleRemove(file?.data?.path)}
                        >
                          Remove
                        </Dropdown.Item>
                      </div>
                    </CardDropdown>
                  </Flex>
                ))}
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={6}>
          <div className={"sticky-sidebar"}>
            <Card className={"mb-3"}>
              <Card.Header>
                <h5>Group Session</h5>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>
                        Minimum Participants{" "}
                        <span className={"text-danger"}>*</span>
                      </Form.Label>
                      <Field name={"min_participants"}>
                        {({ field }) => (
                          <Form.Control
                            placeholder="Minimum Participants"
                            type={"number"}
                            value={field.value}
                            onChange={(e) =>
                              setFieldValue(field.name, e.target.value)
                            }
                          />
                        )}
                      </Field>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>
                        Maximum Participants{" "}
                        <span className={"text-danger"}>*</span>
                      </Form.Label>
                      <Field name={"max_participants"}>
                        {({ field }) => (
                          <Form.Control
                            type={"number"}
                            placeholder="Maximum Participants"
                            value={field.value}
                            onChange={(e) =>
                              setFieldValue(field.name, e.target.value)
                            }
                          />
                        )}
                      </Field>
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group>
                  <Field name={"is_recurring"}>
                    {({ field }) => (
                      <Form.Switch
                        className={"mt-3"}
                        label="Recurring Sessions"
                        checked={field.value}
                        onChange={(e) =>
                          setFieldValue(field.name, e.target.checked)
                        }
                      />
                    )}
                  </Field>
                </Form.Group>
              </Card.Body>
            </Card>
            <Card>
              <Card.Header>
                <h5>Additional Information</h5>
              </Card.Header>
              <Card.Body>
                <Form.Group>
                  <Form.Label>
                    Target Audience <span className={"text-danger"}>*</span>
                  </Form.Label>
                  <Field name={"target_audience"}>
                    {({ field }) => (
                      <Form.Control
                        type={"text"}
                        value={field.value}
                        placeholder={"Target Audience"}
                        onChange={(e) =>
                          setFieldValue(field.name, e.target.value)
                        }
                      />
                    )}
                  </Field>
                </Form.Group>
                <Form.Group>
                  <Form.Label>
                    Agenda <span className={"text-danger"}>*</span>
                  </Form.Label>
                  <Field name={`agenda`}>
                    {({ field }) => (
                      <div className="create-course-description-textarea">
                        <TinymceEditor
                          height="13.438rem"
                          value={field.value}
                          handleChange={(newValue) => {
                            setFieldValue(field.name, newValue);
                          }}
                        />
                      </div>
                    )}
                  </Field>
                </Form.Group>
              </Card.Body>
            </Card>
            <Card className={"mt-3"}>
              <Card.Header>
                <h5>Schedule</h5>
              </Card.Header>
              <Card.Body>
                <FieldArray
                  name={"schedules"}
                  render={(arrayHelpers) => (
                    <>
                      {values.schedules && values.schedules.length > 0
                        ? values.schedules.map((field, index) => (
                            <>
                              <div className="bg-white border dark__bg-1100 p-3 position-relative rounded-1 mb-2">
                                <div className="position-absolute end-0 top-0 mt-2 me-3 z-index-1">
                                  <Button
                                    size="sm"
                                    variant="link"
                                    className="p-0"
                                    onClick={() => arrayHelpers.remove(index)}
                                  >
                                    <FontAwesomeIcon
                                      className="text-danger"
                                      icon="times-circle"
                                    />
                                  </Button>
                                </div>

                                <Row className="gx-2 gy-3">
                                  <Col lg="6">
                                    <Form.Group>
                                      <Form.Label>
                                        Start Time{" "}
                                        <span className={"text-danger"}>*</span>
                                      </Form.Label>
                                      <Field
                                        name={`schedules.${index}.start_time`}
                                      >
                                        {({ field }) => (
                                          <DatePicker
                                            selected={
                                              values?.schedules[index]
                                                ?.start_time
                                                ? new Date(
                                                    values?.schedules[
                                                      index
                                                    ]?.start_time
                                                  )
                                                : null
                                            }
                                            defaultValue=""
                                            onChange={(newDate) => {
                                              setFieldValue(
                                                field.name,
                                                new Date(newDate).toISOString()
                                              );
                                            }}
                                            dateFormat="MM/dd/yyyy HH:mm"
                                            fixedHeight
                                            showTimeSelect
                                            className="form-control"
                                            customInput={
                                              <CustomDateInput
                                                formControlProps={{
                                                  placeholder:
                                                    "MM/dd/yyyy HH:mm",
                                                }}
                                              />
                                            }
                                          />
                                        )}
                                      </Field>
                                      <FormErrors
                                        errors={errors?.schedules}
                                        error={"start_time"}
                                        index={index}
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col lg="6">
                                    <Form.Group>
                                      <Form.Label>
                                        End Time{" "}
                                        <span className={"text-danger"}>*</span>
                                      </Form.Label>
                                      <Field
                                        name={`schedules.${index}.end_time`}
                                      >
                                        {({ field }) => (
                                          <DatePicker
                                            selected={
                                              values?.schedules[index]?.end_time
                                                ? new Date(
                                                    values?.schedules[
                                                      index
                                                    ]?.end_time
                                                  )
                                                : null
                                            }
                                            defaultValue=""
                                            onChange={(newDate) => {
                                              setFieldValue(
                                                field.name,
                                                new Date(newDate).toISOString()
                                              );
                                            }}
                                            fixedHeight
                                            showTimeSelect
                                            className="form-control"
                                            dateFormat="MM/dd/yyyy HH:mm"
                                            customInput={
                                              <CustomDateInput
                                                formControlProps={{
                                                  placeholder:
                                                    "MM/dd/yyyy HH:mm",
                                                }}
                                              />
                                            }
                                          />
                                        )}
                                      </Field>
                                      <FormErrors
                                        errors={errors?.schedules}
                                        error={"end_time"}
                                        index={index}
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col lg={12}>
                                    <Form.Group>
                                      <Form.Label>
                                        Max Participants{" "}
                                        <span className={"text-danger"}>*</span>
                                      </Form.Label>
                                      <Field
                                        name={`schedules.${index}.max_participants`}
                                      >
                                        {({ field }) => (
                                          <Form.Control
                                            type={"number"}
                                            placeholder={"Max Participants"}
                                            value={field.value}
                                            onChange={(e) =>
                                              setFieldValue(
                                                field.name,
                                                e.target.value
                                              )
                                            }
                                          />
                                        )}
                                      </Field>
                                    </Form.Group>
                                  </Col>
                                </Row>
                              </div>
                            </>
                          ))
                        : ""}
                      <IconButton
                        onClick={() =>
                          arrayHelpers.insert(values.schedules.length, "")
                        }
                        variant="falcon-default"
                        size="sm"
                        icon="plus"
                        transform="shrink-3"
                      >
                        Add Item
                      </IconButton>
                    </>
                  )}
                />
              </Card.Body>
            </Card>
          </div>
        </Col>
      </Row>
    </Fragment>
  );
};

export default TeacherGigForm;
