import React from "react";
import ProfileDropdown from "components/navbar/top/ProfileDropdown";
import { Nav } from "react-bootstrap";
import { HeaderLanguages } from "./HeaderLanguages";

const TopNavRightSideNavItem = () => {
  return (
    <Nav
      navbar
      className="navbar-nav-icons ms-auto flex-row align-items-center"
      as="ul"
    >
      <HeaderLanguages />
      <ProfileDropdown />
    </Nav>
  );
};

export default TopNavRightSideNavItem;
