import { Card, Col, Form, Offcanvas, Row, Spinner } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useBreakpoints } from "../../hooks/useBreakpoints";
import GigFilters from "./GigFilters";
import Flex from "../../components/common/Flex";
import GigHeader from "./GigHeader";
import GigGrid from "./GigGrid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useQuery from "../../hooks/useQuery";
import { api } from "../../utils/api";
import AdvanceTablePagination from "../../components/common/advance-table/AdvanceTablePagination";

const Gigs = () => {
  const [loading, setLoading] = useState(false);
  const [gigs, setGigs] = useState([]);
  const [count, setCount] = useState(0);
  const [length, setLength] = useState(0);
  const [showFilterOffcanvas, setShowFilterOffcanvas] = useState(false);
  const [gigPerPage, setGigPerPage] = useState(6);

  const { breakpoints } = useBreakpoints();
  const query = useQuery();

  const getGigs = async (q) => {
    setLoading(true);
    await api.get(`/gig/gig/?${q.toString()}`).then((res) => {
      setGigs(res?.data?.results);
      setCount(res?.data?.count);
    });
    setLoading(false);
  };

  useEffect(() => {
    getGigs(query);
    setLength(gigs?.length);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Row className="g-3">
        {breakpoints.up("xl") && (
          <Col xl={3}>
            <GigFilters />
          </Col>
        )}
        {loading ? (
          <Flex justifyContent="center" className="p-2 mb-2">
            <Spinner animation={"border"} variant={"primary"} />
          </Flex>
        ) : (
          <Col xl={9}>
            <GigHeader
              layout={"grid"}
              setShowFilterOffcanvas={setShowFilterOffcanvas}
            />
            <Row className="mb-3 g-3">
              {gigs?.length > 0 ? (
                gigs?.map((gig) => (
                  <Col key={gig.id} md={6} xxl={4}>
                    <GigGrid gig={gig} />
                  </Col>
                ))
              ) : (
                <Card className="bg-transparent shadow-none">
                  <Card.Body className="border-2 border-dashed border-400 border rounded text-center py-5">
                    <div className="fs-10">
                      <FontAwesomeIcon
                        icon="exclamation-triangle"
                        className="fs-3 mb-3"
                      />
                      <h5>No Gigs Found!</h5>
                      <p className="mb-0">
                        Your search did not match any Gigs. Please try again.
                      </p>
                    </div>
                  </Card.Body>
                </Card>
              )}
            </Row>
            {gigs?.length > 0 && (
              <Card>
                <Card.Body>
                  <Row className="g-3 flex-column flex-sm-row flex-center justify-content-sm-between">
                    <Col
                      xs="auto"
                      as={Flex}
                      alignItems="center"
                      className="d-none d-sm-flex"
                    >
                      <small className="d-none d-sm-block me-2">Show:</small>
                      <Form.Select
                        size="sm"
                        value={gigPerPage}
                        onChange={({ target }) => {
                          setGigPerPage(target.value);
                        }}
                        style={{ maxWidth: "4.875rem" }}
                      >
                        <option value={2}>2</option>
                        <option value={4}>4</option>
                        <option value={6}>6</option>
                        <option value={count}>All</option>
                      </Form.Select>
                    </Col>
                    <AdvanceTablePagination
                      length={length}
                      fetch={getGigs}
                      itemsPerPage={gigPerPage}
                      query={query}
                      count={count}
                    />
                  </Row>
                </Card.Body>
              </Card>
            )}
          </Col>
        )}
      </Row>
      {breakpoints.down("xl") && (
        <Offcanvas
          show={showFilterOffcanvas}
          onHide={() => setShowFilterOffcanvas(false)}
          placement="start"
          className="offcanvas offcanvas-filter-sidebar"
        >
          <GigFilters isOffcanvas={true} setShow={setShowFilterOffcanvas} />
        </Offcanvas>
      )}
    </>
  );
};

export default Gigs;
