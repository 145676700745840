import React from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import Avatar from "components/common/Avatar";
import paths from "routes/paths";
import { useAppContext } from "../../../providers/AppProvider";
import { useTranslation } from "react-i18next";
import { api } from "../../../utils/api";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt, faUser } from "@fortawesome/free-solid-svg-icons";
import CSRFToken from "../../../helpers/CSRFToken";

const ProfileDropdown = () => {
  const {
    config: { avatar },
    setConfig,
  } = useAppContext();

  const { t } = useTranslation("topbar");

  const handleLogout = async () => {
    await api
      .post("/account/logout/")
      .then(() => {
        setConfig("isAuthenticated", false);
        setConfig("permissions", []);
        toast.success("successfully logged out.", { theme: "colored" });
      })
      .catch(() => {
        toast.error("An error has occurred.", { theme: "colored" });
      });
  };

  return (
    <Dropdown navbar={true} as="li">
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        to="#!"
        className="pe-0 ps-2 nav-link"
      >
        <Avatar src={avatar} />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
          <Dropdown.Item as={Link} to={paths.userProfile}>
            <FontAwesomeIcon icon={faUser} className={"me-2"} /> Profile &amp;
            account
          </Dropdown.Item>
          <Dropdown.Divider />
          <CSRFToken />
          <Dropdown.Item as={Link} onClick={handleLogout} to={paths.cardLogout}>
            <FontAwesomeIcon icon={faSignOutAlt} className={"me-2"} />{" "}
            {t("profile.logout")}
          </Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;
