import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import Bottombar from "./Bottombar";
import useVisibilityObserver from "hooks/useVisibilityObserver";
import { formatDateTime } from "../../../../helpers/utils";
import { Field } from "formik";
import CustomDateInput from "../../../../components/common/CustomDateInput";
import DatePicker from "react-datepicker";
import FormError from "../../../errors/FormError";
import { useNavigate } from "react-router-dom";
import { api } from "../../../../utils/api";
import { toast } from "react-toastify";
import paths from "../../../../routes/paths";

const PublishCourse = ({
  values,
  setFieldValue,
  errors,
  setErrors,
  setLoading,
  setFormData,
}) => {
  const [settingOpen, setSettingOpen] = useState(null);
  const targetElRef = useRef();
  const { isVisible: inViewport, observer } = useVisibilityObserver(
    targetElRef,
    "0px"
  );
  const navigate = useNavigate();

  const publishStatsSettings = [
    {
      name: "condition",
      value: "Draft",
      icon: "file",
      component: (
        <Form.Group className={"mt-3 mb-3"}>
          <Form.Label>
            Condition <span className={"text-danger"}>*</span>
          </Form.Label>
          <Field name={"condition"}>
            {({ field }) => (
              <Form.Select
                value={field.value}
                onChange={(e) => setFieldValue(field.name, e.target.value)}
              >
                <option value={"draft"}>Draft</option>
                <option value={"published"}>Published</option>
              </Form.Select>
            )}
          </Field>
          <FormError error={errors?.condition} />
        </Form.Group>
      ),
    },
    {
      name: "privacy",
      value: "Public",
      icon: "unlock-alt",
      component: (
        <Form.Group className={"mt-3 mb-3"}>
          <Form.Label>
            Privacy <span className={"text-danger"}>*</span>
          </Form.Label>
          <Field name={"privacy"}>
            {({ field }) => (
              <Form.Select
                value={field.value}
                onChange={(e) => setFieldValue(field.name, e.target.value)}
              >
                <option value={"private"}>Private</option>
                <option value={"public"}>Public</option>
              </Form.Select>
            )}
          </Field>
          <FormError error={errors?.privacy} />
        </Form.Group>
      ),
    },
    {
      name: "visibility",
      value: "Global Search",
      icon: "globe",
      component: (
        <Form.Group className={"mt-3 mb-3"}>
          <Form.Label>
            Visibility <span className={"text-danger"}>*</span>
          </Form.Label>
          <Field name={"visibility"}>
            {({ field }) => (
              <Form.Select
                value={field.value}
                onChange={(e) => setFieldValue(field.name, e.target.value)}
              >
                <option value={"search"}>Global Search</option>
              </Form.Select>
            )}
          </Field>
          <FormError error={errors?.visibility} />
        </Form.Group>
      ),
    },
    {
      name: "publish",
      value: formatDateTime(new Date().toISOString()),
      icon: "clock",
      component: (
        <Form.Group className={"mt-3"}>
          <Form.Label>
            Publish on <span className={"text-danger"}>*</span>
          </Form.Label>
          <Field name={"publish"}>
            {({ field }) => (
              <DatePicker
                selected={values?.publish ? new Date(values?.publish) : null}
                defaultValue=""
                onChange={(newDate) => {
                  setFieldValue(field.name, new Date(newDate).toISOString());
                }}
                dateFormat="MM/dd/yyyy HH:mm"
                fixedHeight
                showTimeSelect
                className="form-control"
                customInput={
                  <CustomDateInput
                    formControlProps={{
                      placeholder: "MM/dd/yyyy HH:mm",
                    }}
                  />
                }
              />
            )}
          </Field>
          <FormError error={errors?.publish} />
        </Form.Group>
      ),
    },
  ];

  useEffect(() => {
    return () => {
      observer &&
        targetElRef.current &&
        // eslint-disable-next-line
        observer.unobserve(targetElRef.current);
    };
  }, [observer]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    await api
      .post("/course/course/", values)
      .then((res) => {
        toast.success("Course created successfully", { theme: "colored" });
        navigate(paths.courses("course-list"));
      })
      .catch((err) => {
        setFormData(values);
        toast.error("An error has occurred", { theme: "colored" });
        setErrors(err?.response?.data);
      });
    setLoading(false);
  };

  return (
    <>
      <Card className="mb-lg-3 order-lg-0 order-1">
        <Card.Header className="py-2 d-flex flex-between-center">
          <h5 className="mb-0">Publish your Course</h5>
          <Button
            variant="link"
            size="sm"
            className="px-0 fw-medium text-secondary "
          >
            <span className="d-lg-none d-xxl-inline-block">Preview</span>
            <FontAwesomeIcon icon="external-link-alt" className="ms-2 fs-11" />
          </Button>
        </Card.Header>
        <Card.Body className="bg-body-tertiary">
          <ul className="list-unstyled fs-10 mb-0">
            {publishStatsSettings.map((setting, index) => (
              <>
                <li
                  key={setting.name}
                  className={`hover-actions-trigger ${
                    publishStatsSettings.length - 1 !== index && "mb-2"
                  }`}
                >
                  <FontAwesomeIcon icon={setting.icon} className="me-2" />
                  <span className="ms-1">{setting.name}:</span>
                  <span className="text-800 fw-medium me-2">
                    {" "}
                    {setting.value}
                  </span>
                  <Button
                    variant="link"
                    size="sm"
                    className="hover-actions p-0 lh-sm"
                    onClick={() => setSettingOpen(setting.name)}
                  >
                    Edit
                  </Button>
                </li>
                {settingOpen === setting.name && setting.component}
              </>
            ))}
          </ul>
        </Card.Body>
        <Card.Footer ref={targetElRef} className="py-2">
          <Row className="flex-between-center g-0">
            <Col xs="auto">
              <Button
                variant="link"
                size="sm"
                className="fw-medium text-secondary p-0"
              >
                Save as Draft
              </Button>
            </Col>
            <Col xs="auto">
              <Button
                size="md"
                variant="primary"
                className="px-xxl-5 px-4"
                onClick={(e) => handleSubmit(e)}
              >
                Publish
              </Button>
            </Col>
          </Row>
        </Card.Footer>
      </Card>
      <Bottombar inViewport={inViewport} />
    </>
  );
};

export default PublishCourse;
