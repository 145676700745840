import React from "react";
// import Testimonial from "./Testimonial";
import { useAppContext } from "../../providers/AppProvider";
import { Navigate } from "react-router-dom";
import paths from "../../routes/paths";
import { withTranslation } from "react-i18next";

const Landing = () => {
  const {
    config: { isAuthenticated },
  } = useAppContext();

  if (isAuthenticated) return <Navigate to={paths.lms} replace />;

  return <></>;
};

export default withTranslation(["landing", "common"])(Landing);
