import React, { useState } from "react";
import FalconDropzone from "components/common/FalconDropzone";
import avatarImg from "assets/img/team/avatar.png";
import { isIterableArray } from "helpers/utils";
import Avatar from "components/common/Avatar";
import cloudUpload from "assets/img/icons/cloud-upload.svg";
import Flex from "components/common/Flex";
import { Col, Form, Row } from "react-bootstrap";
import { useAuthWizardContext } from "providers/AuthWizardProvider";
import { Field } from "formik";
import FormError from "../../../errors/FormError";
import CustomDateInput from "components/common/CustomDateInput";
import DatePicker from "react-datepicker";

const PersonalForm = ({ values, setFieldValue, errors }) => {
  const { user } = useAuthWizardContext();
  const [avatar, setAvatar] = useState([
    ...(user.avatar ? user.avatar : []),
    { src: avatarImg },
  ]);
  const [date, setDate] = useState(null);

  return (
    <>
      <Row className="mb-3">
        <Col md="auto">
          <Avatar
            size="4xl"
            src={
              isIterableArray(avatar) ? avatar[0]?.base64 || avatar[0]?.src : ""
            }
          />
        </Col>
        <Col md>
          <FalconDropzone
            files={avatar}
            onChange={(files) => {
              setAvatar(files);
              setFieldValue("avatar", files[0]);
            }}
            multiple={false}
            accept="image/*"
            placeholder={
              <>
                <Flex justifyContent="center">
                  <img src={cloudUpload} alt="" width={25} className="me-2" />
                  <p className="fs-9 mb-0 text-700">
                    Upload your profile picture
                  </p>
                </Flex>
                <p className="mb-0 w-75 mx-auto text-400">
                  Upload a 300x300 jpg image with a maximum size of 400KB
                </p>
              </>
            }
          />
          <FormError error={errors?.avatar} />
        </Col>
      </Row>

      <Row>
        <Col>
          <Form.Group>
            <Form.Label>Phone number</Form.Label>
            <Field name={"number"}>
              {({ field }) => (
                <Form.Control
                  type={"number"}
                  value={field.value}
                  onChange={(e) => setFieldValue(field.name, e.target.value)}
                />
              )}
            </Field>
            <FormError error={errors?.number} />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Heading</Form.Label>
            <Field name={"heading"}>
              {({ field }) => (
                <Form.Control
                  type={"text"}
                  value={field.value}
                  onChange={(e) => setFieldValue(field.name, e.target.value)}
                />
              )}
            </Field>
            <FormError error={errors?.heading} />
          </Form.Group>
        </Col>
      </Row>

      <Form.Group>
        <Form.Label>Gender</Form.Label>
        <Field name={"gender"}>
          {({ field }) => (
            <Form.Select
              placeholder="Select your gender..."
              value={field.value}
              onChange={(e) => setFieldValue(field.name, e.target.value)}
            >
              <option>Select your gender...</option>
              <option value={"Male"}>Male</option>
              <option value={"Female"}>Female</option>
              <option value={"Other"}>Other</option>
            </Form.Select>
          )}
        </Field>
        <FormError error={errors?.gender} />
      </Form.Group>

      <Form.Group>
        <Form.Label>Date of Birth</Form.Label>
        <Field name={"birth_date"}>
          {({ field }) => (
            <DatePicker
              selected={date}
              onChange={(date) => {
                setDate(date);
                setFieldValue(field.name, date.toISOString().split("T")[0]);
              }}
              customInput={<CustomDateInput />}
            />
          )}
        </Field>
        <FormError error={errors?.birth_date} />
      </Form.Group>

      <Form.Group>
        <Form.Label>Introduction</Form.Label>
        <Field name={"introduction"}>
          {({ field }) => (
            <Form.Control
              as="textarea"
              value={field.value}
              onChange={(e) => setFieldValue(field.name, e.target.value)}
            />
          )}
        </Field>
        <FormError error={errors?.introduction} />
      </Form.Group>
    </>
  );
};

export default PersonalForm;
