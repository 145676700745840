import React, { useEffect, useState } from "react";
import MultiSelect from "components/common/MultiSelect";
import TinymceEditor from "components/common/TinymceEditor";
import { Card, Col, Form, Row } from "react-bootstrap";
import { Field } from "formik";
import { api } from "../../../../utils/api";
import useQuery from "../../../../hooks/useQuery";

const CourseInformationForm = ({ values, setFieldValue, errors }) => {
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [tags, setTags] = useState([]);

  const query = useQuery();

  const fetchCategories = async () => {
    await api.get("/course/coursecategory/").then((response) => {
      setCategories(response?.data?.results);
    });
  };

  const fetchSubCategories = async () => {
    await api
      .get(`/course/coursesubcategory/?${query.toString()}`)
      .then((response) => {
        setSubCategories(response?.data?.results);
      });
  };

  const fetchTags = async () => {
    await api.get("/course/coursetag/").then((response) => {
      setTags(response?.data?.results);
    });
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    fetchTags();
  }, []);

  const tagOptions = tags?.map((tag) => ({
    value: tag?.id,
    label: tag?.content,
  }));

  return (
    <Card className="mb-3">
      <Card.Header as="h5">Course Information</Card.Header>
      <Card.Body className="bg-body-tertiary">
        <Row className="gx-2 gy-3">
          <Col md="12">
            <Form.Group controlId="courseTitle">
              <Form.Label>
                Course Title<span className="text-danger">*</span>
              </Form.Label>
              <Field name="title">
                {({ field }) => (
                  <Form.Control
                    type="text"
                    required
                    placeholder="Course Title"
                    value={field.value}
                    onChange={(e) => setFieldValue(field.name, e.target.value)}
                  />
                )}
              </Field>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group>
              <Form.Label>
                Category<span className="text-danger">*</span>
              </Form.Label>
              <Field name={"category"}>
                {({ field }) => (
                  <Form.Select
                    required
                    value={field.value}
                    onChange={(e) => {
                      setFieldValue(field.name, e.target.value);
                      query.set("category", e.target.value);
                      fetchSubCategories();
                    }}
                  >
                    <option value="">Select a category...</option>
                    {categories?.map((category) => (
                      <option value={category?.id}>{category?.name}</option>
                    ))}
                  </Form.Select>
                )}
              </Field>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group>
              <Form.Label>
                Sub-category<span className="text-danger">*</span>
              </Form.Label>
              <Field name={"subcategory"}>
                {({ field }) => (
                  <Form.Select
                    required
                    value={field.value}
                    onChange={(e) => {
                      setFieldValue(field.name, e.target.value);
                    }}
                  >
                    <option value="">Select a sub-category...</option>
                    {subCategories?.map((subCategory) => (
                      <option value={subCategory?.id}>
                        {subCategory?.name}
                      </option>
                    ))}
                  </Form.Select>
                )}
              </Field>
            </Form.Group>
          </Col>
          <Col xs="12">
            <Form.Group>
              <Form.Label>
                Tags<span className="text-danger">*</span>
              </Form.Label>
              <Field name="tags">
                {({ field }) => (
                  <MultiSelect
                    {...field}
                    closeMenuOnSelect={false}
                    isMulti
                    options={tagOptions}
                    placeholder="Select tags..."
                    value={selectedTags}
                    onChange={(newValue) => {
                      setSelectedTags(newValue);
                      setFieldValue(
                        field.name,
                        newValue?.map((v) => v?.value)
                      );
                    }}
                  />
                )}
              </Field>
            </Form.Group>
          </Col>
          <Col xs="12">
            <Form.Group>
              <Form.Label>
                Course Description<span className="text-danger">*</span>
              </Form.Label>
              <Field name={"excerpt"}>
                {({ field }) => (
                  <div className="create-course-description-textarea">
                    <TinymceEditor
                      height="13.438rem"
                      value={field.value}
                      handleChange={(newValue) => {
                        setFieldValue(field.name, newValue);
                      }}
                    />
                  </div>
                )}
              </Field>
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default CourseInformationForm;
