import React, { useState } from "react";
import PropTypes from "prop-types";
import { Card, Form, Nav, ProgressBar } from "react-bootstrap";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AccountForm from "./AccountForm";
import PersonalForm from "./PersonalForm";
import Success from "./Success";
import { useAuthWizardContext } from "providers/AuthWizardProvider";
import IconButton from "components/common/IconButton";
import WizardModal from "./WizardModal";
import Flex from "components/common/Flex";
import { useAppContext } from "providers/AppProvider";
import {
  faCertificate,
  faUserGraduate,
  faUserTie,
} from "@fortawesome/free-solid-svg-icons";
import CertificationForm from "./CertificationForm";
import { Formik } from "formik";
import CSRFToken from "../../../../helpers/CSRFToken";
import EducationForm from "./EducationForm";
import ExperienceForm from "./ExperienceForm";
import { api } from "../../../../utils/api";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const WizardLayout = ({ variant, validation, progressBar }) => {
  const [formData, setFormData] = useState({
    isAccepted: false,
    certifications: [],
    educations: [],
    experiences: [],
  });
  const [errors, setErrors] = useState({});
  const { isRTL } = useAppContext();
  const { step, setStep } = useAuthWizardContext();
  const { t } = useTranslation(["common"]);

  const [modal, setModal] = useState(false);

  const navItems = [
    {
      icon: "lock",
      label: "Account",
    },
    {
      icon: "user",
      label: "Personal",
    },
    {
      icon: faCertificate,
      label: "Certification",
    },
    {
      icon: faUserGraduate,
      label: "Education",
    },
    {
      icon: faUserTie,
      label: "Experience",
    },
    {
      icon: "thumbs-up",
      label: "Done",
    },
  ];

  const onSubmitData = async (data) => {
    if (step === 5) {
      await api
        .post("/account/auth/teacher/register/", data)
        .then((res) => {
          toast.success("Account successfully created.", { theme: "colored" });
          setStep(step + 1);
        })
        .catch((err) => {
          toast.error("An error has occurred.", { theme: "colored" });
          setFormData(data);
          setErrors(err?.response?.data);
          setStep(1);
        });
    } else {
      setStep(step + 1);
    }
  };

  const toggle = () => setModal(!modal);

  const handleNavs = (targetStep) => {
    if (step !== 6) {
      if (targetStep < step) {
        setStep(targetStep);
      } else {
      }
    } else {
      toggle();
    }
  };

  return (
    <>
      <WizardModal modal={modal} setModal={setModal} />
      <Formik
        initialValues={formData}
        onSubmit={(values) => console.log(values)}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <CSRFToken />
            <Card noValidate className="theme-wizard mb-5">
              <Card.Header
                className={classNames("bg-body-tertiary", {
                  "px-4 py-3": variant === "pills",
                  "pb-2": !variant,
                })}
              >
                <Nav className="justify-content-center" variant={variant}>
                  {variant === "pills"
                    ? navItems.map((item, index) => (
                        <NavItemPill
                          key={item.label}
                          index={index + 1}
                          step={step}
                          handleNavs={handleNavs}
                          icon={item.icon}
                          label={item.label}
                        />
                      ))
                    : navItems.map((item, index) => (
                        <NavItem
                          key={item.label}
                          index={index + 1}
                          step={step}
                          handleNavs={handleNavs}
                          icon={item.icon}
                          label={item.label}
                        />
                      ))}
                </Nav>
              </Card.Header>
              {progressBar && (
                <ProgressBar now={step * 20} style={{ height: 2 }} />
              )}
              <Card.Body className="fw-normal px-md-6 py-4">
                {step === 1 && (
                  <AccountForm
                    values={values}
                    errors={errors}
                    setFieldValue={setFieldValue}
                  />
                )}
                {step === 2 && (
                  <PersonalForm
                    values={values}
                    errors={errors}
                    setFieldValue={setFieldValue}
                  />
                )}
                {step === 3 && (
                  <CertificationForm
                    values={values}
                    errors={errors}
                    setFieldValue={setFieldValue}
                  />
                )}
                {step === 4 && (
                  <EducationForm
                    values={values}
                    errors={errors}
                    setFieldValue={setFieldValue}
                  />
                )}
                {step === 5 && (
                  <ExperienceForm
                    values={values}
                    errors={errors}
                    setFieldValue={setFieldValue}
                  />
                )}
                {step === 6 && (
                  <Success
                    layout="card"
                    email={values?.email}
                    titleTag="h3"
                    t={t}
                  />
                )}
              </Card.Body>
              <Card.Footer
                className={classNames("px-md-6 bg-body-tertiary", {
                  "d-none": step === 6,
                  " d-flex": step < 6,
                })}
              >
                <IconButton
                  variant="link"
                  icon={isRTL ? "chevron-right" : "chevron-left"}
                  iconAlign="left"
                  transform="down-1 shrink-4"
                  className={classNames("px-0 fw-semibold", {
                    "d-none": step === 1,
                  })}
                  onClick={() => {
                    setStep(step - 1);
                  }}
                >
                  Prev
                </IconButton>

                <IconButton
                  variant="primary"
                  className="ms-auto px-5"
                  icon={isRTL ? "chevron-left" : "chevron-right"}
                  iconAlign="right"
                  transform="down-1 shrink-4"
                  onClick={(e) => onSubmitData(values)}
                >
                  Next
                </IconButton>
              </Card.Footer>
            </Card>
          </Form>
        )}
      </Formik>
    </>
  );
};

const NavItem = ({ index, step, handleNavs, icon, label }) => {
  return (
    <Nav.Item>
      <Nav.Link
        className={classNames("fw-semibold", {
          done: index < 6 ? step > index : step > 5,
          active: step === index,
        })}
        onClick={() => handleNavs(index)}
      >
        <span className="nav-item-circle-parent">
          <span className="nav-item-circle">
            <FontAwesomeIcon icon={icon} />
          </span>
        </span>
        <span className="d-none d-md-block mt-1 fs-10">{label}</span>
      </Nav.Link>
    </Nav.Item>
  );
};

const NavItemPill = ({ index, step, handleNavs, icon, label }) => {
  return (
    <Nav.Item>
      <Nav.Link
        className={classNames("fw-semibold", {
          done: step > index,
          active: step === index,
        })}
        onClick={() => handleNavs(index)}
      >
        <Flex alignItems="center" justifyContent="center">
          <FontAwesomeIcon icon={icon} />
          <span className="d-none d-md-block mt-1 fs-10 ms-2">{label}</span>
        </Flex>
      </Nav.Link>
    </Nav.Item>
  );
};

WizardLayout.propTypes = {
  variant: PropTypes.oneOf(["pills"]),
  validation: PropTypes.bool,
  progressBar: PropTypes.bool,
};

NavItemPill.propTypes = {
  index: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  handleNavs: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

NavItem.propTypes = NavItemPill.propTypes;

export default WizardLayout;
