export const rootPaths = {
  root: "/",
  adminRoot: "admin",
  dashboardRoot: "dashboard",
  authRoot: "authentication",
  authCardRoot: "card",
  errorsRoot: "errors",
  coursesRoot: "courses",
  gigRoot: "gig",
  feedRoot: "feed",
  accountRoot: "account",
  userRoot: "user",
  groupRoot: "group",
  teacherRoot: "teacher",
};
// eslint-disable-next-line
export default {
  error404: `/${rootPaths.errorsRoot}/404`,
  error500: `/${rootPaths.errorsRoot}/500`,
  cardLogin: `/${rootPaths.authRoot}/${rootPaths.authCardRoot}/login`,
  cardRegister: `/${rootPaths.authRoot}/${rootPaths.authCardRoot}/register`,
  cardTeach: `/${rootPaths.authRoot}/${rootPaths.authCardRoot}/teach`,
  cardLogout: `/${rootPaths.authRoot}/${rootPaths.authCardRoot}/logout`,
  cardForgotPassword: `/${rootPaths.authRoot}/${rootPaths.authCardRoot}/forgot-password`,
  cardResetPassword: `/${rootPaths.authRoot}/${rootPaths.authCardRoot}/reset-password`,
  cardConfirmMail: `/${rootPaths.authRoot}/${rootPaths.authCardRoot}/confirm-mail`,
  cardLockScreen: `/${rootPaths.authRoot}/${rootPaths.authCardRoot}/lock-screen`,
  cardTeacherSignup: `/${rootPaths.authRoot}/${rootPaths.authCardRoot}/teacher-signup`,
  lms: `/${rootPaths.adminRoot}/${rootPaths.dashboardRoot}/lms`,
  createCourse: `/${rootPaths.adminRoot}/${rootPaths.coursesRoot}/new`,
  courses: (courseLayout) =>
    `/${rootPaths.adminRoot}/${rootPaths.coursesRoot}/course-details${
      courseLayout ? `/${courseLayout}` : ""
    }`,
  courseDetails: (courseId) =>
    `/${rootPaths.adminRoot}/${rootPaths.coursesRoot}${
      courseId ? `/${courseId}` : ""
    }`,
  studentOverview: `/${rootPaths.adminRoot}/${rootPaths.coursesRoot}/student-overview`,
  userProfile: `/${rootPaths.adminRoot}/${rootPaths.accountRoot}/profile`,
  userList: `/${rootPaths.adminRoot}/${rootPaths.accountRoot}/${rootPaths.userRoot}`,
  userCreate: `/${rootPaths.adminRoot}/${rootPaths.accountRoot}/${rootPaths.userRoot}/new`,
  userEdit: (id) =>
    `/${rootPaths.adminRoot}/${rootPaths.accountRoot}/${rootPaths.userRoot}/${
      id ? id : ""
    }/edit`,
  userDetail: (id) =>
    `/${rootPaths.adminRoot}/${rootPaths.accountRoot}/${rootPaths.userRoot}/${
      id ? id : ""
    }/detail`,
  groupList: `/${rootPaths.adminRoot}/${rootPaths.accountRoot}/${rootPaths.groupRoot}`,
  groupCreate: `/${rootPaths.adminRoot}/${rootPaths.accountRoot}/${rootPaths.groupRoot}/new`,
  groupEdit: (id) =>
    `/${rootPaths.adminRoot}/${rootPaths.accountRoot}/${rootPaths.groupRoot}/${
      id ? id : ""
    }/edit`,
  teacherList: `/${rootPaths.adminRoot}/${rootPaths.teacherRoot}`,
  teacherCreate: `/${rootPaths.adminRoot}/${rootPaths.teacherRoot}/new`,
  teacherEdit: (id) =>
    `/${rootPaths.adminRoot}/${rootPaths.teacherRoot}/${id}/edit`,
  teacherProfile: (id) =>
    `/${rootPaths.adminRoot}/${rootPaths.teacherRoot}/${id}/detail`,
  feed: `/${rootPaths.adminRoot}/${rootPaths.feedRoot}`,
  gigList: `/${rootPaths.adminRoot}/${rootPaths.gigRoot}`,
  gigCreate: `/${rootPaths.adminRoot}/${rootPaths.gigRoot}/new`,
  gigDetail: (id) =>
    `/${rootPaths.adminRoot}/${rootPaths.gigRoot}/${id}/detail`,
  gigEdit: (id) => `/${rootPaths.adminRoot}/${rootPaths.gigRoot}/${id}/edit`,
};
