import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { api } from "../../utils/api";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CSRFToken from "../../helpers/CSRFToken";
import FormError from "../errors/FormError";
import { useAppContext } from "../../providers/AppProvider";
import useQuery from "../../hooks/useQuery";
import paths from "../../routes/paths";

const forgotPasswordPaths = {
  simple: paths.simpleForgotPassword,
  split: paths.splitForgotPassword,
  card: paths.cardForgotPassword,
};

const LoginForm = ({ hasLabel, layout, t }) => {
  const { setConfig } = useAppContext();
  const navigate = useNavigate();
  // State
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
    remember: false,
  });
  const [errors, setErrors] = useState({});

  let query = useQuery();

  useEffect(() => {
    if (!query.has("next")) query.set("next", "/dashboard");
  }, [query]);

  // Handler
  const handleSubmit = (e) => {
    e.preventDefault();
    api
      .post("/account/login/", formData)
      .then((res) => {
        setConfig("isAuthenticated", true);
        setConfig("group", res?.data?.user?.group_name);
        setConfig("permissions", res?.data?.user?.permissions);
        setConfig("isSuperUser", res?.data?.user?.is_superuser);
        setConfig("isStaff", res?.data?.user?.is_staff);
        setConfig("avatar", res?.data?.user?.url_path);
        if (
          res?.data?.user?.group_name === process.env.REACT_APP_ADMIN_GROUP_NAME
        ) {
          setConfig("navbarPosition", "vertical");
          navigate(paths.lms);
        } else if (
          res?.data?.user?.group_name ===
          process.env.REACT_APP_STUDENT_GROUP_NAME
        ) {
          setConfig("navbarPosition", "vertical");
          navigate(paths.lms);
        } else if (
          res?.data?.user?.group_name ===
          process.env.REACT_APP_TEACHER_GROUP_NAME
        ) {
          setConfig("navbarPosition", "vertical");
          navigate(paths.lms);
        }
        toast.success(
          `${t("loginSuccess", { ns: "common" })} ${res?.data?.user?.username}`,
          {
            theme: "colored",
          }
        );
      })
      .catch((err) => {
        setErrors(err?.response?.data);
        toast.error(`${t("error", { ns: "common" })} ${err.toString()}`, {
          theme: "colored",
        });
      });
  };

  const handleFieldChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <CSRFToken />
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>{t("fields.username")}</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? t("fields.username") : ""}
          value={formData.username}
          name="username"
          onChange={handleFieldChange}
          type="text"
        />
        <FormError error={errors?.username} />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>{t("fields.email")}</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? t("fields.email") : ""}
          value={formData.email}
          name="email"
          onChange={handleFieldChange}
          type="email"
        />
        <FormError error={errors?.email} />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>{t("fields.password")}</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? t("fields.password") : ""}
          value={formData.password}
          name="password"
          onChange={handleFieldChange}
          type="password"
        />
        <FormError error={errors?.password} />
      </Form.Group>

      <Row className="justify-content-between align-items-center">
        <Col xs="auto">
          <Form.Check type="checkbox" id="rememberMe" className="mb-0">
            <Form.Check.Input
              type="checkbox"
              name="remember"
              checked={formData.remember}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  remember: e.target.checked,
                })
              }
            />
            <Form.Check.Label className="mb-0 text-700">
              {t("fields.remember")}
            </Form.Check.Label>
          </Form.Check>
        </Col>

        <Col xs="auto">
          <Link className="fs--1 mb-0" to={forgotPasswordPaths[layout]}>
            {t("fields.forgotPass")}
          </Link>
        </Col>
      </Row>

      <Form.Group>
        <Button type="submit" color="primary" className="mt-3 w-100">
          {t("login.title")}
        </Button>
        <FormError error={errors?.non_field_errors} />
      </Form.Group>
    </Form>
  );
};

LoginForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool,
  t: PropTypes.func,
  i18n: PropTypes.object,
};

LoginForm.defaultProps = {
  layout: "simple",
  hasLabel: false,
};

export default LoginForm;
