import { useEffect, useState } from "react";
import avatarImg from "../../../../assets/img/team/avatar.png";
import { useAppContext } from "../../../../providers/AppProvider";
import { useNavigate, useParams } from "react-router-dom";
import useQuery from "../../../../hooks/useQuery";
import { getGroups, getPermissions } from "../../actions/GroupsPermissions";
import { Card, CardHeader, Col, Form, Row, Spinner } from "react-bootstrap";
import { Field, Formik } from "formik";
import CSRFToken from "../../../../helpers/CSRFToken";
import { withTranslation } from "react-i18next";
import { isIterableArray, withPermission } from "../../../../helpers/utils";
import Avatar from "../../../../components/common/Avatar";
import FalconDropzone from "../../../../components/common/FalconDropzone";
import FormError from "../../../errors/FormError";
import cloudUpload from "../../../../assets/img/icons/cloud-upload.svg";
import { format } from "date-fns";
import DatePicker from "react-datepicker";
import TinymceEditor from "../../../../components/common/TinymceEditor";
import Select from "react-select";
import IconButton from "../../../../components/common/IconButton";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { api } from "../../../../utils/api";
import { toast } from "react-toastify";
import paths from "../../../../routes/paths";
import Flex from "../../../../components/common/Flex";

const UserAddEdit = ({ t }) => {
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const [date, setDate] = useState(null);
  const [groups, setGroups] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState(null);
  const [permissions, setPermissions] = useState([]);
  const [permissionsPage, setPermissionsPage] = useState(1);
  const [selectedPermissions, setSelectedPermissions] = useState(null);
  const [formData, setFormData] = useState({
    password: "",
    email: "",
    username: "",
    first_name: "",
    last_name: "",
    is_active: true,
    is_staff: false,
    is_superuser: false,
    is_learner: false,
    is_teacher: false,
    number: "",
    avatar: null,
    gender: null,
    birth_date: null,
    groups: [],
    user_permissions: [],
  });
  const [avatar, setAvatar] = useState([{ src: avatarImg }]);

  const {
    config: { group },
  } = useAppContext();
  const navigate = useNavigate();
  const query = useQuery();
  const { id } = useParams();

  const fetchUser = async () => {
    setLoading(true);
    await api
      .get(`/account/user/${id}/`)
      .then(({ data }) => {
        setFormData(data);
        setAvatar([{ src: data?.url_path }]);
        setDate(data?.birth_date ? new Date(data?.birth_date) : null);
        setSelectedGroups({ label: data?.group_name, value: data?.groups });
        setSelectedPermissions(
          data?.user_permissions?.map((perm, index) => ({
            label: data?.permissions_name[index],
            value: perm,
          }))
        );
      })
      .catch((err) => {
        if (err?.response?.status === 404) {
          navigate(paths.error404);
        }
      });
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    getGroups(query)
      .then((res) => setGroups(res?.results))
      .catch(() => {});
    setLoading(false);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setLoading(true);
    query.set("page_size", "50");
    query.set("page", permissionsPage.toString());
    getPermissions(query)
      .then((res) => setPermissions(res?.results))
      .catch(() => {});
    setLoading(false);
    // eslint-disable-next-line
  }, [permissionsPage]);

  useEffect(() => {
    if (id) fetchUser();
    // eslint-disable-next-line
  }, []);

  let groupOptions = groups.map((group) => ({
    label: group.name,
    value: group.id,
  }));

  groupOptions = [
    { label: "---------------------------", value: "" },
    ...groupOptions,
  ];

  let permissionOptions = permissions.map((permission) => ({
    label: permission.name,
    value: permission.id,
  }));

  const handleSubmit = async (e, values) => {
    e.preventDefault();
    setLoading(true);
    if (id) {
      await api
        .patch(`/account/user/${id}/`, values)
        .then(() => {
          toast.success("User successfully updated.", { theme: "colored" });
          navigate(paths.userList);
        })
        .catch((error) => {
          toast.error("An error has occurred.", { theme: "colored" });
          setFormData(values);
          setErrors(error?.response?.data);
        });
    } else {
      await api
        .post("/account/user/", values)
        .then(() => {
          toast.success("User successfully added.", { theme: "colored" });
          navigate(paths.userList);
        })
        .catch((error) => {
          toast.error("An error has occurred.", { theme: "colored" });
          setFormData(values);
          setErrors(error?.response?.data);
        });
    }
    setLoading(false);
  };

  return loading ? (
    <Flex justifyContent="center" alignItems={"center"} className="p-2 mb-2">
      <Spinner animation={"border"} variant={"primary"} />
    </Flex>
  ) : (
    <Formik
      initialValues={formData}
      onSubmit={(values) => {
        console.log(values);
      }}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <CSRFToken />
          <Row className={"g-3 mt-1 mb-3"}>
            <Col xxl={8} xl={12}>
              <Row className="g-3">
                <Col xs={12}>
                  <Card>
                    <Card.Header>
                      <h5 className="mb-0 text-muted">{t("fields.basic")}</h5>
                    </Card.Header>
                    <Card.Body className="bg-light pb-0">
                      <Form.Group>
                        <Row className="mb-3">
                          <Col md="auto">
                            <Avatar
                              size="4xl"
                              src={
                                isIterableArray(avatar)
                                  ? avatar[0]?.base64 || avatar[0]?.src
                                  : ""
                              }
                            />
                          </Col>
                          <Col md>
                            <Field name="avatar">
                              {() => (
                                <FalconDropzone
                                  files={avatar}
                                  onChange={(files) => {
                                    setAvatar(files);
                                    setFieldValue("avatar", files[0].base64);
                                  }}
                                  multiple={false}
                                  accept="image/*"
                                  placeholder={
                                    <>
                                      <Flex justifyContent="center">
                                        <img
                                          src={cloudUpload}
                                          alt=""
                                          width={25}
                                          className="me-2"
                                        />
                                        <p className="fs-0 mb-0 text-700">
                                          {t("fields.upload")}
                                        </p>
                                      </Flex>
                                      <p className="mb-0 w-75 mx-auto text-400">
                                        {t("fields.uploadDesc")}
                                      </p>
                                    </>
                                  }
                                />
                              )}
                            </Field>
                            <FormError error={errors.avatar} />
                          </Col>
                        </Row>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>
                          {t("fields.username")}:{" "}
                          <span className={"text-danger"}>*</span>
                        </Form.Label>
                        <Field name="username">
                          {({ field }) => (
                            <Form.Control
                              type="text"
                              name="username"
                              placeholder={t("fields.username")}
                              errors={errors}
                              formGroupProps={{
                                className: "mb-3",
                              }}
                              onChange={(e) => {
                                setFieldValue(field.name, e.target.value);
                              }}
                              value={field.value}
                            />
                          )}
                        </Field>
                        <FormError error={errors.username} />
                      </Form.Group>
                      <Row>
                        <Col md={6}>
                          <Form.Group>
                            <Form.Label>
                              {t("fields.first_name")}:{" "}
                              <span className={"text-warning"}>*</span>
                            </Form.Label>
                            <Field name="first_name">
                              {({ field }) => (
                                <Form.Control
                                  type="text"
                                  label={t("fields.first_name")}
                                  name="first_name"
                                  placeholder={t("fields.first_name")}
                                  errors={errors}
                                  formGroupProps={{
                                    className: "mb-3",
                                  }}
                                  onChange={(e) => {
                                    setFieldValue(field.name, e.target.value);
                                  }}
                                  value={field.value}
                                />
                              )}
                            </Field>
                            <FormError error={errors.first_name} />
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group>
                            <Form.Label>
                              {t("fields.last_name")}:{" "}
                              <span className={"text-warning"}>*</span>
                            </Form.Label>
                            <Field name="last_name">
                              {({ field }) => (
                                <Form.Control
                                  type="text"
                                  label={t("fields.last_name")}
                                  name="last_name"
                                  placeholder={t("fields.last_name")}
                                  errors={errors}
                                  formGroupProps={{
                                    className: "mb-3",
                                  }}
                                  onChange={(e) => {
                                    setFieldValue(field.name, e.target.value);
                                  }}
                                  value={field.value}
                                />
                              )}
                            </Field>
                            <FormError error={errors.last_name} />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Form.Group className={"mb-3"}>
                        <Row>
                          <Form.Label>{t("fields.date_of_birth")}:</Form.Label>
                        </Row>
                        <Row>
                          <Field name="birth_date">
                            {({ field }) => (
                              <DatePicker
                                selected={date}
                                onChange={(date) => {
                                  setDate(date);
                                  setFieldValue(
                                    field.name,
                                    format(date, "yyyy-MM-dd")
                                  );
                                }}
                                formatWeekDay={(day) => day.slice(0, 3)}
                                className="form-control"
                                dateFormat="MM-dd-yyyy"
                                placeholderText={t("fields.MMDDYYYY")}
                              />
                            )}
                          </Field>
                        </Row>
                        <FormError error={errors.birth_date} />
                      </Form.Group>
                    </Card.Body>
                  </Card>
                </Col>
                <Col xs={12}>
                  <Card className={"pb-3"}>
                    <Card.Header>
                      <h5 className="mb-0 text-muted">{t("fields.contact")}</h5>
                    </Card.Header>
                    <Card.Body className="bg-light pb-0">
                      <Form.Group>
                        <Form.Label>
                          {t("fields.email")}:{" "}
                          <span className={"text-warning"}>*</span>
                        </Form.Label>
                        <Field name="email">
                          {({ field }) => (
                            <Form.Control
                              type="email"
                              label={t("fields.email")}
                              name="email"
                              placeholder={t("fields.email")}
                              errors={errors}
                              formGroupProps={{
                                className: "mb-3",
                              }}
                              onChange={(e) => {
                                setFieldValue(field.name, e.target.value);
                              }}
                              value={field.value}
                            />
                          )}
                        </Field>
                        <FormError error={errors.email} />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>{t("fields.phone")}:</Form.Label>
                        <Field name="number">
                          {({ field }) => (
                            <Form.Control
                              type="number"
                              label="Phone"
                              name="number"
                              placeholder={t("fields.phone")}
                              errors={errors}
                              formGroupProps={{
                                className: "mb-3",
                              }}
                              formControlProps={{
                                className: "input-spin-none",
                              }}
                              onChange={(e) =>
                                setFieldValue(field.name, e.target.value)
                              }
                              value={field.value}
                            />
                          )}
                        </Field>
                        <FormError error={errors.number} />
                      </Form.Group>
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg={12}>
                  <Card>
                    <Card.Header>
                      <h5 className="mb-0 text-muted">{t("fields.extra")}</h5>
                    </Card.Header>
                    <Card.Body className="bg-light pb-0">
                      <Row>
                        <Col lg={6}>
                          <Form.Group>
                            <Form.Label>{t("fields.heading")}:</Form.Label>
                            <Field name={"heading"}>
                              {({ field }) => (
                                <Form.Control
                                  type={"text"}
                                  name={"heading"}
                                  value={field.value}
                                  onChange={(e) =>
                                    setFieldValue(field.name, e.target.value)
                                  }
                                  placeholder={t("fields.heading")}
                                />
                              )}
                            </Field>
                            <FormError error={errors?.heading} />
                          </Form.Group>
                        </Col>
                        <Col lg={6}>
                          <Form.Group>
                            <Form.Label>{t("fields.cover")}:</Form.Label>
                            <Field name={"cover"}>
                              {({ field }) => (
                                <Form.Control
                                  type={"file"}
                                  name={"cover"}
                                  onChange={(e) =>
                                    setFieldValue(field.name, e.target.files[0])
                                  }
                                />
                              )}
                            </Field>
                            <FormError error={errors?.cover} />
                          </Form.Group>
                        </Col>
                        <Form.Group className={"mb-3"}>
                          <Form.Label>{t("fields.intro")}:</Form.Label>
                          <Field name={"introduction"}>
                            {({ field }) => (
                              <TinymceEditor
                                value={field.value}
                                handleChange={(newValue) =>
                                  setFieldValue(field.name, newValue)
                                }
                              />
                            )}
                          </Field>
                          <FormError error={errors?.introduction} />
                        </Form.Group>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col xxl={4} xl={12}>
              <div className={"sticky-sidebar"}>
                <Card>
                  <CardHeader>
                    <h5 className="mb-0 text-muted">
                      {t("fields.user_status")}{" "}
                      <span className={"text-warning"}>*</span>
                    </h5>
                  </CardHeader>
                  <Card.Body>
                    <Form.Group>
                      <Field name="is_active">
                        {({ field }) => (
                          <Form.Switch
                            label={t("fields.active")}
                            name="is_active"
                            errors={errors}
                            formGroupProps={{
                              className: "mb-3",
                            }}
                            onChange={(e) =>
                              setFieldValue(field.name, e.target.checked)
                            }
                            checked={field.value}
                          />
                        )}
                      </Field>
                      <FormError error={errors.is_active} />
                    </Form.Group>
                    {group?.indexOf(process.env.REACT_APP_ADMIN_GROUP_NAME) !==
                      -1 && (
                      <>
                        <Form.Group>
                          <Field name="is_staff">
                            {({ field }) => (
                              <Form.Switch
                                label={t("fields.staff")}
                                name="is_staff"
                                errors={errors}
                                formGroupProps={{
                                  className: "mb-3",
                                }}
                                onChange={(e) =>
                                  setFieldValue(field.name, e.target.checked)
                                }
                                checked={field.value}
                              />
                            )}
                          </Field>
                          <FormError error={errors.is_staff} />
                        </Form.Group>
                        <Form.Group>
                          <Field name="is_superuser">
                            {({ field }) => (
                              <Form.Switch
                                label={t("fields.super_user")}
                                name="is_superuser"
                                errors={errors}
                                formGroupProps={{
                                  className: "mb-3",
                                }}
                                onChange={(e) =>
                                  setFieldValue(field.name, e.target.checked)
                                }
                                checked={field.value}
                              />
                            )}
                          </Field>
                          <FormError error={errors.is_superuser} />
                        </Form.Group>
                        <Form.Group>
                          <Field name="is_learner">
                            {({ field }) => (
                              <Form.Switch
                                label={t("fields.learner")}
                                name="is_learner"
                                errors={errors}
                                formGroupProps={{
                                  className: "mb-3",
                                }}
                                onChange={(e) =>
                                  setFieldValue(field.name, e.target.checked)
                                }
                                checked={field.value}
                              />
                            )}
                          </Field>
                          <FormError error={errors.is_learner} />
                        </Form.Group>
                        <Form.Group>
                          <Field name="is_teacher">
                            {({ field }) => (
                              <Form.Switch
                                label={t("fields.teacher")}
                                name="is_teacher"
                                errors={errors}
                                formGroupProps={{
                                  className: "mb-3",
                                }}
                                onChange={(e) =>
                                  setFieldValue(field.name, e.target.checked)
                                }
                                checked={field.value}
                              />
                            )}
                          </Field>
                          <FormError error={errors.is_teacher} />
                        </Form.Group>
                      </>
                    )}
                  </Card.Body>
                </Card>
                {group?.indexOf(process.env.REACT_APP_ADMIN_GROUP_NAME) !==
                  -1 && (
                  <Card className={"mt-3"}>
                    <CardHeader>
                      <h5 className="mb-0 text-muted">
                        {t("fields.groups_permissions")}{" "}
                        <span className={"text-warning"}>*</span>
                      </h5>
                    </CardHeader>
                    <Card.Body>
                      <Form.Group>
                        <Form.Label>{t("fields.groups")}</Form.Label>
                        <Field name={"groups"}>
                          {({ field }) => (
                            <Select
                              options={groupOptions}
                              placeholder={`${t("select", {
                                ns: "common",
                              })} ${t("fields.groups")}`}
                              classNamePrefix="react-select"
                              value={selectedGroups}
                              onChange={(selectedOption) => {
                                setFieldValue(field.name, selectedOption.value);
                                setSelectedGroups(selectedOption);
                              }}
                            />
                          )}
                        </Field>
                        <FormError error={errors.groups} />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>{t("fields.permissions")}</Form.Label>
                        <Field name={"user_permissions"}>
                          {({ field }) => (
                            <Select
                              options={permissionOptions}
                              placeholder={`${t("select", {
                                ns: "common",
                              })} ${t("fields.permissions")}`}
                              onInputChange={(newValue) => {
                                query.set("search", newValue);
                                getPermissions(query).then((response) =>
                                  setPermissions(response?.results)
                                );
                              }}
                              value={selectedPermissions}
                              onChange={(newValue) => {
                                const values = newValue.map(
                                  (option) => option.value
                                );
                                setFieldValue(field.name, values);
                                setSelectedPermissions(newValue);
                              }}
                              onMenuScrollToBottom={() => {
                                setPermissionsPage(
                                  (prevState) => prevState + 1
                                );
                              }}
                              classNamePrefix={"react-select"}
                              isMulti
                            />
                          )}
                        </Field>
                        <FormError error={errors.user_permissions} />
                      </Form.Group>
                    </Card.Body>
                  </Card>
                )}
                <Card className={"mt-3"}>
                  <CardHeader>
                    <h5 className="mb-0 text-muted">{t("fields.password")}</h5>
                  </CardHeader>
                  <Card.Body>
                    <Form.Group>
                      <Form.Label>{t("fields.password")}</Form.Label>
                      <Field name={"password"}>
                        {({ field }) => (
                          <Form.Control
                            type="password"
                            label="Password"
                            name="password"
                            placeholder={t("fields.password")}
                            errors={errors}
                            formGroupProps={{
                              className: "mb-3",
                            }}
                            formControlProps={{
                              className: "input-spin-none",
                            }}
                            onChange={(e) =>
                              setFieldValue(field.name, e.target.value)
                            }
                            value={field.value}
                          />
                        )}
                      </Field>
                      <FormError error={errors.password} />
                    </Form.Group>
                  </Card.Body>
                </Card>
              </div>
            </Col>
          </Row>
          <Card>
            <CardHeader>
              <Flex
                justifyContent={"between"}
                alignItems={"center"}
                wrap={"wrap"}
              >
                <span>
                  <ul>
                    <li className={"text-decoration-none text-danger"}>
                      * {t("mandatory", { ns: "common" })}
                    </li>
                    <li className={"text-decoration-none text-warning"}>
                      * {t("recommended", { ns: "common" })}
                    </li>
                  </ul>
                </span>
                <IconButton
                  icon={faSave}
                  onClick={(e) => handleSubmit(e, values)}
                >
                  <span className="d-none d-sm-inline-block ms-1">
                    {t("fields.save")}
                  </span>
                </IconButton>
              </Flex>
            </CardHeader>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export default withPermission(
  withTranslation(["users", "common"])(UserAddEdit),
  "accounts.add_user"
);
