import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Navbar } from 'react-bootstrap';
import handleNavbarTransparency from 'helpers/handleNavbarTransparency';
import LandingRightSideNavItem from './LandingRightSideNavItem';
import { topNavbarBreakpoint } from 'config';
import { useAppContext } from '../../providers/AppProvider';

const NavbarStandard = () => {
  const {
    config: { isDark }
  } = useAppContext();
  const [navbarCollapsed, setNavbarCollapsed] = useState(true);

  useEffect(() => {
    window.addEventListener('scroll', handleNavbarTransparency);
    return () => window.removeEventListener('scroll', handleNavbarTransparency);
  }, []);

  return (
    <Navbar
      variant={isDark ? 'light' : 'dark'}
      expand={topNavbarBreakpoint}
      fixed={'top'}
      className={classNames('bg-light navbar-standard navbar-theme', {
        'bg-100': !navbarCollapsed && isDark,
        'bg-transparent': !navbarCollapsed && !isDark
      })}
    >
      <Container fluid>
        <Navbar.Brand
          as={Link}
          to="/"
          style={{ color: 'rgba(22, 62, 115, 0.65)' }}
        >
          Shortcut
        </Navbar.Brand>
        <Navbar.Toggle onClick={() => setNavbarCollapsed(!navbarCollapsed)} />
        <Navbar.Collapse className="scrollbar">
          <LandingRightSideNavItem />
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavbarStandard;
