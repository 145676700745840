import React from "react";
import { Card, Col, Image, Row } from "react-bootstrap";

const TrainerInfo = ({ trainer }) => {
  return (
    <Card>
      <Card.Body>
        <Row className="g-3 align-items-center">
          <Col md={6} lg={12} className="text-center">
            <Image
              src={trainer?.url_path}
              alt={trainer?.name}
              fluid
              className="rounded-3"
            />
          </Col>
          <Col md={6} lg={12}>
            <Row className="row-cols-1 g-0">
              <Col className="text-center">
                <h4>{trainer?.name}</h4>
                <h5 className="mb-1 text-800 fs-9">{trainer?.title}</h5>
                <p className="mb-0 fs-10">{trainer?.organization}</p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default TrainerInfo;
