import {useTranslation} from "react-i18next";
import {usePermissions} from "../providers/PermissionsProvider";
import {useNavigate} from "react-router-dom";
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import AdvanceTableSearchBox from "../components/common/advance-table/AdvanceTableSearchBox";
import IconButton from "../components/common/IconButton";
import React, {useState} from "react";
import PropTypes from "prop-types";
import CSRFToken from "../helpers/CSRFToken";
import {api} from "../utils/api";
import {toast} from "react-toastify";
import useQuery from "../hooks/useQuery";
import {faFile, faFileCsv, faFileExcel, faFileImport, faFilePdf} from "@fortawesome/free-solid-svg-icons";
import Flex from "../components/common/Flex";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const BaseTableHeader = ({
                             title,
                             selectedRowIds,
                             table,
                             count,
                             fetchData,
                             deletePermission,
                             addPermission,
                             exportPermission,
                             creationPath,
                             data,
                             exportLink,
                             deleteLink,
                             setLoading,
                             importPath,
                             importPermission,
                             fields,
                         }) => {
    const [selectedAction, setSelectedAction] = useState("")
    const [exportModal, setExportModal] = useState(false)
    const [selectedFields, setSelectedFields] = useState(fields)
    const {t} = useTranslation('common');
    const {hasPermission} = usePermissions()
    const navigate = useNavigate();
    const query = useQuery();

    const handleBulkExport = async (format) => {
        setLoading(true)
        await api.post(`${exportLink}`, {format: format, fields: selectedFields}, {
            responseType: 'blob'
        })
            .then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${t(`titles.${title}`)}-${new Date().toISOString()}.${format}`);
                document.body.appendChild(link);
                link.click();
                toast.success(`${t(`titles.${title}`)} successfully exported.`, {theme: "colored"})
            })
            .catch(() => {
            })
        setLoading(false)
    }

    const handleBulkDelete = async () => {
        setLoading(true)
        Object.keys(selectedRowIds)?.map(id => query.append("id", data[id]?.id))
        await api.post(`${deleteLink}?${query.toString()}`)
            .then(res => {
                toast.success(`${title} successfully deleted.`, {theme: "colored"})
                fetchData(query)
            })
        setLoading(false)
    }

    const handleClick = e => {
        e.preventDefault();
        switch (selectedAction) {
            case "delete":
                handleBulkDelete();
                break;
            default:
                return false;
        }
    }

    return (
        <Row className="flex-between-center">
            <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
                <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
                    {t(`titles.${title}`)}
                </h5>
                <span className={'text-muted ms-2'}>({count})</span>
            </Col>
            <Col xs="auto" sm={6} lg={4}>
                <AdvanceTableSearchBox table={table} fetch={fetchData} placeholder={t('search')}/>
            </Col>
            <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
                <CSRFToken/>
                {Object.keys(selectedRowIds).length > 0 ? (
                    <div className="d-flex">
                        <Form.Select size="sm" aria-label="Bulk actions"
                                     onChange={(e) => setSelectedAction(e.target.value)}>
                            <option>{t('bulkActions')}</option>
                            {hasPermission(deletePermission) &&
                                <option value="delete">{t('delete')}</option>
                            }
                        </Form.Select>
                        <Button
                            type="button"
                            variant="falcon-default"
                            size="sm"
                            className="ms-2"
                            onClick={(e) => handleClick(e)}
                        >
                            {t('apply')}
                        </Button>
                    </div>
                ) : (
                    <div id="orders-actions">
                        {(hasPermission(addPermission) && creationPath) &&
                            <IconButton
                                variant="falcon-default"
                                size="sm"
                                icon="plus"
                                transform="shrink-3"
                                onClick={() => navigate(creationPath)}
                            >
                                <span className="d-none d-sm-inline-block ms-1">{t('new')}</span>
                            </IconButton>
                        }
                        {hasPermission(exportPermission) &&
                            <IconButton
                                variant="falcon-default"
                                size="sm"
                                icon="external-link-alt"
                                className={"ms-2"}
                                transform="shrink-3"
                                onClick={() => setExportModal(true)}
                            >
                                <span className="d-none d-sm-inline-block ms-1">{t('export')}</span>
                            </IconButton>
                        }
                        {hasPermission(importPermission) &&
                            <IconButton
                                variant="falcon-default"
                                size="sm"
                                icon={faFileImport}
                                transform="shrink-3"
                                className="ms-2"
                                onClick={() => navigate(importPath)}
                            >
                                <span className="d-none d-sm-inline-block ms-1">{t('import', {ns: "common"})}</span>
                            </IconButton>
                        }
                    </div>
                )}
            </Col>
            <Modal size={"lg"} centered onHide={() => setExportModal(!exportModal)} show={exportModal}>
                <Modal.Header closeButton>
                    <h4>{t('export')} {t(`titles.${title}`)}</h4>
                </Modal.Header>
                <Modal.Body>
                    <p>This exporter will export the following fields:</p>
                    <Form.Group>
                        {fields?.map((field, i) => (
                            <Form.Check
                                name={"field"}
                                label={field}
                                type="checkbox"
                                checked={selectedFields.includes(field)}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setSelectedFields([...selectedFields, field]);
                                    }
                                    else {
                                        setSelectedFields(selectedFields.filter(f => f !== field));
                                    }
                                }}
                            />
                        ))}
                    </Form.Group>
                    <Flex justifyContent={"between"} alignItems={"center"} wrap={"wrap"}>
                        <div>
                            <Button variant={"outline-success"} size={"lg"} onClick={(e) => {
                                query.set("format", "xlsx")
                                handleBulkExport("xlsx")
                            }}>
                                <FontAwesomeIcon icon={faFileExcel}/>
                            </Button>
                            <p className={"text-center"}>Excel</p>
                        </div>
                        <div>
                            <Button variant={"outline-primary"} size={"lg"} onClick={(e) => {
                                query.set("format", "csv")
                                handleBulkExport("csv")
                            }}>
                                <FontAwesomeIcon icon={faFileCsv}/>
                            </Button>
                            <p className={"text-center"}>CSV</p>
                        </div>
                        <div>
                            <Button variant={"outline-warning"} size={"lg"} onClick={(e) => {
                                query.set("format", "json")
                                handleBulkExport("json")
                            }}>
                                <FontAwesomeIcon icon={faFile}/>
                            </Button>
                            <p className={"text-center"}>JSON</p>
                        </div>
                        <div>
                            <Button variant={"outline-danger"} size={"lg"} onClick={(e) => {
                                query.set("format", "pdf")
                                handleBulkExport("pdf")
                            }}>
                                <FontAwesomeIcon icon={faFilePdf}/>
                            </Button>
                            <p className={"text-center"}>PDF</p>
                        </div>
                    </Flex>
                </Modal.Body>
            </Modal>
        </Row>
    );
}

BaseTableHeader.propTypes = {
    selectedRowIds: PropTypes.object
};

export default BaseTableHeader;
