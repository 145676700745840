import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import envelope from "../../../../assets/img/icons/spot-illustrations/16.png";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import paths from "../../../../routes/paths";

const Success = ({ layout, titleTag: TitleTag, t, i18n, email }) => {
  return (
    <>
      <Row>
        <Col className="text-center">
          <img
            className="d-block mx-auto mb-4"
            src={envelope}
            alt="sent"
            width={100}
          />
          <TitleTag>{t("verifyMail.title")}</TitleTag>
          <p>
            {t("verifyMail.mailSent")} <strong>{email}</strong>.{" "}
            {t("verifyMail.subtitle")}
          </p>
          <Button
            as={Link}
            color="primary"
            size="sm"
            className="mt-3"
            to={paths.cardLogin}
          >
            <FontAwesomeIcon
              icon="chevron-left"
              transform="shrink-4 down-1"
              className="me-1"
            />
            {t("verifyMail.loginReturn")}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default Success;
