import axios from 'axios';

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  withCredentials: true,
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'x-csrftoken'
});

const cancelTokens = {};

api.interceptors.request.use(
  config => {
    const url = config.url;
    if (url.includes('dj-rest-auth') || url.includes('account')) {
      return config;
    }
    if (cancelTokens[url]) {
      cancelTokens[url].cancel('Canceled due to new request');
    }
    cancelTokens[url] = axios.CancelToken.source();
    config.cancelToken = cancelTokens[url].token;
    return config;
  },
  error => Promise.reject(error)
);

api.interceptors.response.use(
  response => response,
  error => {
    if (axios.isCancel(error)) {
      // Request was canceled, handle it gracefully
      console.log('Request canceled:', error.message);
      return new Promise(() => {}); // return a promise that doesn't resolve or reject
    }
    // if (error.response.status === 403) {
    //     localStorage.removeItem("isAuthenticated");
    //     window.location.href = "/authentication/split/login";
    // }
    // if (error.response.status === 404) {
    //     window.location.href = "/error/404";
    // }
    // if (error?.response?.status === 500) window.location.href = "/error/404";
    return Promise.reject(error);
  }
);

// api.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     if (error.response.status === 403) {
//       localStorage.removeItem("isAuthenticated");
//       window.location.href = "/authentication/split/login";
//     }
//     return Promise.reject(error);
//   }
// );
