export const version = "4.5.0";
export const navbarBreakPoint = "xl"; // Vertical navbar breakpoint
export const topNavbarBreakpoint = "lg";
export const themeVariants = ["light", "dark", "auto"];
export const settings = {
  isFluid: true,
  isRTL: false,
  isDark: false,
  isAuthenticated: false,
  permissions: [],
  isSuperUser: false,
  isStaff: false,
  avatar: "",
  group: "",
  theme: "light",
  navbarPosition: "vertical",
  showBurgerMenu: false, // controls showing vertical nav on mobile
  currency: "$",
  lang: "en",
  isNavbarVerticalCollapsed: false, // toggle vertical navbar collapse
  navbarStyle: "transparent",
};

// eslint-disable-next-line
export default { version, navbarBreakPoint, topNavbarBreakpoint, settings };
