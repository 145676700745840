import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppContext } from "../../../providers/AppProvider";
import { api } from "../../../utils/api";
import paths from "../../../routes/paths";
import { Spinner } from "react-bootstrap";
import Flex from "../../../components/common/Flex";

const GigDetail = () => {
  const [gig, setGig] = useState({});
  const [loading, setLoading] = useState(false);

  const { id } = useParams();
  const {
    config: { group },
  } = useAppContext();
  const navigate = useNavigate();

  const fetchData = async () => {
    setLoading(true);
    await api
      .get(`/gig/${group}/${id}/`)
      .then((res) => setGig(res?.data))
      .catch((err) => {
        if (err?.response?.status === 404) navigate(paths.error404);
      });
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  return loading ? (
    <Flex justifyContent="center" className="p-2 mb-2">
      <Spinner animation={"border"} variant={"primary"} />
    </Flex>
  ) : (
    <Fragment>{gig?.title}</Fragment>
  );
};

export default GigDetail;
