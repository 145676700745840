import { withPermission } from "../../../helpers/utils";
import { withTranslation } from "react-i18next";
import { Col, Row, Spinner } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import TrainerInfo from "./TrainerInfo";
import TrainerIntro from "./TrainerIntro";
import { api } from "../../../utils/api";
import { useNavigate, useParams } from "react-router-dom";
import paths from "../../../routes/paths";
import Flex from "../../../components/common/Flex";

const TrainerProfile = ({ t, i18n }) => {
  const [loading, setLoading] = useState(false);
  const [trainer, setTrainer] = useState({});

  const { id } = useParams();
  const navigate = useNavigate();

  const fetchTrainer = async () => {
    setLoading(true);
    await api
      .get(`/trainer/trainer/${id}/`)
      .then((res) => {
        setTrainer(res?.data);
      })
      .catch((error) => {
        if (error?.response?.status === 404) navigate(paths.error404);
      });
    setLoading(false);
  };

  useEffect(() => {
    fetchTrainer();
    // eslint-disable-next-line
  }, []);

  return loading ? (
    <Flex justifyContent="center" className="p-2 mb-2">
      <Spinner animation={"border"} variant={"primary"} />
    </Flex>
  ) : (
    <Row className="g-3">
      <Col lg={4} xl={3}>
        <div className="sticky-sidebar top-navbar-height">
          <TrainerInfo trainer={trainer} />
        </div>
      </Col>
      <Col lg={8} xl={9}>
        <TrainerIntro trainer={trainer} />
        {/*<TrainerCourses trainer={trainer} />*/}
      </Col>
    </Row>
  );
};

export default withPermission(
  withTranslation(["trainer", "common"])(TrainerProfile),
  "trainer.view_trainer"
);
