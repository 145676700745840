import React from 'react';
import LogoutContent from '../LogoutContent';

import AuthCardLayout from 'layouts/AuthCardLayout';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const Logout = ({ t }) => {
  return (
    <AuthCardLayout>
      <div className="text-center">
        <LogoutContent layout="card" titleTag="h3" t={t} />
      </div>
    </AuthCardLayout>
  );
};

Logout.propTypes = {
  t: PropTypes.func.isRequired
};

export default withTranslation(['authentication', 'common'])(Logout);
