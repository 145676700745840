import React from "react";
import { Card, Col, Row } from "react-bootstrap";

const TrainerIntro = ({ trainer }) => {
  return (
    <Card className="mb-3">
      <Card.Header className="bg-body-tertiary">
        <h6 className="mb-0">Introduction</h6>
      </Card.Header>
      <Card.Body>
        <Row className="flex-between-center">
          <Col
            xxl={9}
            className="text-1000"
            dangerouslySetInnerHTML={{ __html: trainer?.introduction }}
          ></Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default TrainerIntro;
