import React from "react";
import { Link } from "react-router-dom";
import AuthCardLayout from "layouts/AuthCardLayout";
import RegistrationForm from "../RegistrationForm";
import { Button } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

const Registration = ({ t, i18n }) => {
  return (
    <AuthCardLayout
      leftSideContent={
        <p className="pt-3 text-white">
          Have an account?
          <br />
          <Button
            as={Link}
            variant="outline-light"
            className="mt-2 px-4"
            to="/authentication/card/login"
          >
            Log In
          </Button>
        </p>
      }
      footer={false}
    >
      <h3>Register</h3>
      <RegistrationForm
        layout="card"
        hasLabel
        i18n={i18n}
        t={t}
        as={"student"}
      />
    </AuthCardLayout>
  );
};

Registration.propTypes = {
  t: PropTypes.func.isRequired,
  i18n: PropTypes.object.isRequired,
};

export default withTranslation(["authentication", "common"])(Registration);
