import React, { Fragment } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { Field } from "formik";
import FormError from "../../../errors/FormError";
import { Link } from "react-router-dom";
import paths from "../../../../routes/paths";

const AccountForm = ({ values, setFieldValue, errors, t, i18n }) => {
  return (
    <>
      <Row className="g-2 mb-3">
        <Form.Group>
          <Form.Label>
            Username <span className={"text-danger"}>*</span>
          </Form.Label>
          <Field name={"username"}>
            {({ field }) => (
              <Form.Control
                type={"text"}
                value={field.value}
                onChange={(e) => setFieldValue(field.name, e.target.value)}
              />
            )}
          </Field>
          <FormError error={errors?.username} />
        </Form.Group>

        <Col lg={6}>
          <Form.Group>
            <Form.Label>
              First name <span className={"text-danger"}>*</span>
            </Form.Label>
            <Field name={"first_name"}>
              {({ field }) => (
                <Form.Control
                  type={"text"}
                  value={field.value}
                  onChange={(e) => setFieldValue(field.name, e.target.value)}
                />
              )}
            </Field>
            <FormError error={errors?.first_name} />
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group>
            <Form.Label>
              Last name <span className={"text-danger"}>*</span>
            </Form.Label>
            <Field name={"last_name"}>
              {({ field }) => (
                <Form.Control
                  type={"text"}
                  value={field.value}
                  onChange={(e) => setFieldValue(field.name, e.target.value)}
                />
              )}
            </Field>
            <FormError error={errors?.last_name} />
          </Form.Group>
        </Col>

        <Form.Group>
          <Form.Label>
            Email <span className={"text-danger"}>*</span>
          </Form.Label>
          <Field name={"email"}>
            {({ field }) => (
              <Form.Control
                type={"email"}
                value={field.value}
                onChange={(e) => setFieldValue(field.name, e.target.value)}
              />
            )}
          </Field>
          <FormError error={errors?.email} />
        </Form.Group>

        <Col lg={6}>
          <Form.Group>
            <Form.Label>
              Password <span className={"text-danger"}>*</span>
            </Form.Label>
            <Field name={"password1"}>
              {({ field }) => (
                <Form.Control
                  type={"password"}
                  value={field.value}
                  onChange={(e) => setFieldValue(field.name, e.target.value)}
                />
              )}
            </Field>
            <FormError error={errors?.password1} />
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group>
            <Form.Label>
              Confirm Password <span className={"text-danger"}>*</span>
            </Form.Label>
            <Field name={"password2"}>
              {({ field }) => (
                <Form.Control
                  type={"password"}
                  value={field.value}
                  onChange={(e) => setFieldValue(field.name, e.target.value)}
                />
              )}
            </Field>
            <FormError error={errors?.password2} />
          </Form.Group>
        </Col>
      </Row>

      <Form.Group className="mb-3">
        <Field name={"isAccepted"}>
          {({ field }) => (
            <Form.Check
              type="checkbox"
              id="acceptCheckbox"
              className="form-check"
            >
              <Form.Check.Input
                type="checkbox"
                name="isAccepted"
                onChange={(e) => setFieldValue(field.name, e.target.checked)}
              />

              <Form.Check.Label className="form-label">
                {t("accept")}{" "}
                <Link to={paths.termsConditions}>
                  {t("termsConditions", { ns: "common" })}
                </Link>{" "}
                {t("and")}{" "}
                <Link to={paths.privacyPolicy}>
                  {t("privacyPolicy", { ns: "common" })}
                </Link>
              </Form.Check.Label>
            </Form.Check>
          )}
        </Field>
        <FormError error={errors?.isAccepted} />
      </Form.Group>
    </>
  );
};

export default withTranslation(["users", "common"])(AccountForm);
