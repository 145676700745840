import React, { useState } from "react";
import { Button, Card, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flex from "components/common/Flex";
import { Field } from "formik";

const CourseRequirements = ({ values, setFieldValue, errors, index }) => {
  const [requirements, setRequirements] = useState(values?.requirements);
  const [requirement, setRequirement] = useState("");
  const [editingRequirement, setEditingRequirement] = useState({});
  const [isEditing, setIsEditing] = useState(false);

  const handleRequirementSubmit = () => {
    if (!isEditing) {
      setRequirements([...requirements, { text: requirement }]);
      setRequirement("");
    } else {
      const updatedRequirements = requirements.map((requirement) => {
        if (requirement.text === editingRequirement.text) {
          return {
            ...requirement,
            text: editingRequirement.text,
          };
        }
        return requirement;
      });
      setRequirements(updatedRequirements);
      setEditingRequirement({});
      setIsEditing(false);
    }
  };

  const handleChange = (value, setFieldValue) => {
    isEditing
      ? setEditingRequirement({
          ...editingRequirement,
          text: value,
        })
      : setRequirement(value);
    setFieldValue(`requirements.${index}.text`, value);
  };

  return (
    <Card className="mb-3">
      <Card.Header as="h5" className="bg-body-tertiary">
        Requirements
      </Card.Header>
      <Card.Body className="pt-0">
        <ul className="list-unstyled mb-0">
          {requirements.map((item, index) => (
            <li key={index}>
              <Flex
                alignItems="center"
                className={`hover-actions-trigger py-3 ${
                  index !== 0 && "border-top border-300"
                }`}
              >
                <FontAwesomeIcon
                  icon="grip-lines"
                  className="fs-11 text-secondary me-3"
                />
                <p className="fs-10 mb-0 me-6">{item.text}</p>
                <div className="hover-actions end-0 top-50 translate-middle-y">
                  <OverlayTrigger
                    overlay={
                      <Tooltip style={{ position: "fixed" }} id="removeTooltip">
                        Remove
                      </Tooltip>
                    }
                  >
                    <Button
                      variant="falcon-default"
                      type="button"
                      size="sm"
                      onClick={() =>
                        setRequirements(
                          requirements.filter(({ text }) => text !== item.text)
                        )
                      }
                      className="icon-item icon-item-sm rounded-3 fs-11"
                    >
                      <FontAwesomeIcon icon="times" />
                    </Button>
                  </OverlayTrigger>
                </div>
              </Flex>
            </li>
          ))}
        </ul>
        <Form.Group controlId="courseRequirement" className="position-relative">
          <Field name={`requirements.${index}.text`}>
            {({ field }) => (
              <Form.Control
                type="text"
                required
                value={!isEditing ? requirement : editingRequirement.text}
                className="pe-4"
                onChange={(e) => handleChange(e.target.value, setFieldValue)}
                placeholder="Add another requirement..."
              />
            )}
          </Field>
          <div className="position-absolute end-0 top-50 translate-middle">
            <Button
              variant="link"
              onClick={() => {
                handleRequirementSubmit();
                setFieldValue(`requirements.${index}.text`, "");
              }}
              type="button"
              className="d-flex align-items-center fs-10 text-700 p-0 me-1"
            >
              <FontAwesomeIcon icon="arrow-right" />
            </Button>
          </div>
        </Form.Group>
      </Card.Body>
    </Card>
  );
};

export default CourseRequirements;
