import paths from "./paths";
import {
  faChalkboardTeacher,
  faShareAlt,
  faUserGraduate,
  faUsersCog,
} from "@fortawesome/free-solid-svg-icons";

export const dashboardRoutes = {
  label: "Dashboard",
  labelDisable: true,
  children: [
    {
      name: "Dashboard",
      active: true,
      icon: "chart-pie",
      to: paths.lms,
    },
  ],
};
export const appRoutes = {
  label: "app",
  children: [
    {
      name: "Accounts",
      active: true,
      icon: "user",
      children: [
        {
          name: "Users",
          active: true,
          icon: faUsersCog,
          to: paths.userList,
        },
        {
          name: "Groups",
          active: true,
          icon: "users",
          to: paths.groupList,
        },
      ],
    },
    {
      name: "Courses",
      to: paths.courses("course-list"),
      active: true,
      icon: "graduation-cap",
    },
    {
      name: "Gigs",
      to: paths.gigList,
      active: true,
      icon: faUserGraduate,
    },
    {
      name: "Feed",
      to: paths.feed,
      active: true,
      icon: faShareAlt,
    },
    {
      name: "Teachers",
      to: paths.teacherList,
      active: true,
      icon: faChalkboardTeacher,
    },
  ],
};
// eslint-disable-next-line
export default [dashboardRoutes, appRoutes];
