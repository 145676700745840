import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import coverSrc from "assets/img/generic/4.jpg";
import Flex from "components/common/Flex";
import VerifiedBadge from "components/common/VerifiedBadge";
import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import ProfileBanner from "../../ProfileBanner";
import { api } from "../../../../utils/api";
import CSRFToken from "../../../../helpers/CSRFToken";
import IconButton from "../../../../components/common/IconButton";
import { usePermissions } from "../../../../providers/PermissionsProvider";
import { useAppContext } from "../../../../providers/AppProvider";
import paths from "../../../../routes/paths";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const Banner = ({ user, fetchUser, settings, setLoading, t }) => {
  const [currentUser, setCurrentUser] = useState({});
  const [followed, setFollowed] = useState(false);

  const { hasPermission } = usePermissions();
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    config: { isSuperUser, isActivity },
  } = useAppContext();

  const getCurrentUser = async () => {
    await api
      .get("/account/current_user/")
      .then((res) => setCurrentUser(res?.data));
  };

  const followThisUser = async (e) => {
    await api
      .post("/account/followers/", { follower: user?.id })
      .then(() => {
        setFollowed(true);
        fetchUser();
      })
      .catch(() => {});
  };

  const sendActivationEmail = async (e) => {
    setLoading(true);
    await api
      .post(`/account/send_activation_email/`, { id: id })
      .then((res) => {
        toast.success("Activation email sent successfully.", {
          theme: "colored",
        });
      })
      .catch((err) => {
        toast.error("An error has occurred.", { theme: "colored" });
      });
    setLoading(false);
  };

  const handleDelete = async (e) => {
    const { isConfirmed } = await Swal.fire({
      title: t("sure", { ns: "common" }),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: t("cancel", { ns: "common" }),
      confirmButtonText: `${t("yes", { ns: "common" })}, ${t("deleteConfirm", {
        ns: "common",
      })}`,
    });
    if (isConfirmed) {
      e.preventDefault();
      setLoading(true);
      await api.delete(`/account/user/${id}/`).then(() => {
        toast.success(
          `${t("title")} ${t("deleteSuccess", { ns: "common" })}.`,
          {
            theme: "colored",
          }
        );
        navigate(paths.users);
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    getCurrentUser();
  }, []);

  useEffect(() => {
    if (
      user?.followed_by?.filter((follow) => follow?.user === currentUser?.id)
        .length !== 0
    )
      setFollowed(true);
  }, [currentUser?.id, user?.followed_by]);

  return (
    <ProfileBanner>
      <ProfileBanner.Header
        avatar={user?.url_path}
        coverSrc={user?.cover_url ? user?.cover_url : coverSrc}
        detail={true}
      />
      <ProfileBanner.Body>
        <Row>
          <Col lg={8}>
            <h4 className="mb-1">
              {user?.full_name} {user?.is_active && <VerifiedBadge />}
            </h4>
            <h5 className="fs-0 fw-normal">{user?.heading}</h5>
            <p className="text-500">{user?.address_names}</p>
            {settings?.show_emails && (
              <p className="text-600">
                {t("fields.email")}:{" "}
                <a href={`mailto:${user?.email}`}>{user?.email}</a>
              </p>
            )}
            {settings?.phone_visible ? (
              <p className="text-600">
                {t("fields.phone")}:{" "}
                <a href={`tel:${user?.number}`}>{user?.number}</a>
              </p>
            ) : (
              ""
            )}
            <Flex wrap={"wrap"} alignItems={"center"}>
              {settings?.user_follow && (
                <Button
                  variant="falcon-primary"
                  size="sm"
                  className="px-3 me-2"
                  onClick={followThisUser}
                  disabled={
                    user?.followed_by?.filter(
                      (follow) => follow?.user === currentUser?.id
                    ).length !== 0
                  }
                >
                  <CSRFToken />
                  {followed
                    ? t("following", { ns: "common" })
                    : t("follow", { ns: "common" })}
                </Button>
              )}
              {hasPermission("accounts.change_user") && (
                <IconButton
                  variant="primary"
                  size="sm"
                  icon="edit"
                  transform="shrink-2"
                  iconAlign="middle"
                  className="me-2"
                  onClick={() => navigate(paths.userEdit(id))}
                >
                  <span className="d-none d-xl-inline-block ms-1">
                    {t("edit", { ns: "common" })}
                  </span>
                </IconButton>
              )}
              {(isSuperUser || isActivity) && (
                <IconButton
                  variant="info"
                  size="sm"
                  icon="lock"
                  className="d-none d-sm-block me-2"
                  onClick={sendActivationEmail}
                >
                  <span className="d-none d-xl-inline-block ms-1">
                    {t("sendActivation", { ns: "common" })}
                  </span>
                </IconButton>
              )}
              {hasPermission("accounts.delete_user") && (
                <IconButton
                  variant="danger"
                  size="sm"
                  icon="trash-alt"
                  iconAlign="middle"
                  className="d-none d-sm-block me-2"
                  onClick={handleDelete}
                >
                  <span className="d-none d-xl-inline-block ms-1">
                    {t("delete", { ns: "common" })}
                  </span>
                </IconButton>
              )}
            </Flex>
            <div className="border-dashed border-bottom my-4 d-lg-none" />
          </Col>
          <Col className="ps-2 ps-lg-3">
            {settings?.show_followers && (
              <Link to={`/accounts/user/${user?.id}/followers`}>
                <Flex alignItems="center" className="mb-2">
                  <FontAwesomeIcon
                    icon="user-circle"
                    className="me-2 text-700"
                    style={{ height: "30px", width: "30px" }}
                  />
                  <div className="flex-1">
                    <h6 className="mb-0">
                      {t("see", { ns: "common" })}{" "}
                      {t("followers", { ns: "common" })} (
                      {user?.followed_by?.length})
                    </h6>
                  </div>
                </Flex>
              </Link>
            )}
            {user?.member_in?.slice(0, 3)?.map((item) => (
              <Link
                to={`/activities/activity/${item?.activity}/detail`}
                key={item.id}
              >
                <Flex alignItems="center" className="mb-2">
                  <img
                    src={item?.activity_data?.logo_url}
                    alt={item.activity_name}
                    width={30}
                    className="me-2"
                  />
                  <div className="flex-1">
                    <h6 className="mb-0">{item.activity_name}</h6>
                  </div>
                </Flex>
              </Link>
            ))}
          </Col>
        </Row>
      </ProfileBanner.Body>
    </ProfileBanner>
  );
};

export default Banner;
